import React, { memo } from 'react';
import { Space } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';

import { COLORS } from 'style';
import moment from 'moment';
import { Attempt } from 'type';

type Props = {
  indexAttempt: number;
  content: string;
  answerLength: number;
  setValue: (_: string) => void;
  others: Attempt[];
};

const MultichoiceTextDrag: React.FC<Props> = ({
  indexAttempt,
  content,
  answerLength,
  setValue,
  others,
}) => {
  const widthInput = 72;
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={indexAttempt.toString()}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div>
          <div style={{ paddingTop: 12 }}>
            <span
              style={{
                color: COLORS.TEXT.yellowGrey,
                fontWeight: '600',
                fontSize: '0.9rem',
                marginBottom: 12,
              }}
              dangerouslySetInnerHTML={{
                __html: `Câu hỏi mật thư:&nbsp;<span style="color: #595959;font-size: 1rem;font-weight: 400">${content}</span>`,
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            <div className="d-flex justify-content-center">
              <Space
                size={8}
                style={{
                  justifyContent: 'center',
                  maxWidth: widthInput * 6,
                }}
                wrap
              >
                {new Array(answerLength).fill(null).map((_, index) => (
                  <input
                    key={index}
                    placeholder=""
                    id={`${indexAttempt}-national-language/white-paper-${index}`}
                    style={{
                      borderRadius: '0.3rem',
                      width: widthInput,
                      margin: 0,
                      fontVariant: 'tabular-nums',
                      listStyle: 'none',
                      fontFeatureSettings: 'tnum',
                      position: 'relative',
                      display: 'inline-block',
                      minWidth: 0,
                      padding: '4px 11px',
                      color: 'rgba(0,0,0,.85)',
                      fontSize: 14,
                      lineHeight: 1.5715,
                      backgroundColor: '#fff',
                      backgroundImage: 'none',
                      border: '1px solid #d9d9d9',
                      transition: 'all .3s',
                    }}
                    onChange={() => {
                      setValue(moment().unix().toString());
                    }}
                  />
                  // <Input
                  //   key={index}
                  //   size="middle"
                  //   placeholder=""
                  //   id={`${indexAttempt}-national-language/white-paper-${index}`}
                  //   style={{ borderRadius: '0.3rem', width: widthInput }}
                  //   onChange={() => {
                  //     setValue(moment().unix().toString());
                  //   }}
                  // />
                ))}
              </Space>
            </div>
          </div>

          {others.map((item, index) => {
            const length = _.get(item, `answerLength`, 0);
            const contentOther = _.get(item, 'question.content', '');
            return (
              <div key={index} style={{ paddingTop: 12 }}>
                <span
                  style={{
                    color: COLORS.TEXT.yellowGrey,
                    fontWeight: '600',
                    fontSize: '1rem',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: contentOther,
                  }}
                />
                <div className="d-flex justify-content-center">
                  <Space
                    size={8}
                    style={{
                      justifyContent: 'center',
                      maxWidth: widthInput * 6,
                    }}
                    wrap
                  >
                    {new Array(length).fill(null).map((_, idx) => (
                      <input
                        key={index}
                        placeholder=""
                        id={`${indexAttempt}-national-language/white-paper-${index}-${idx}`}
                        style={{
                          borderRadius: '0.3rem',
                          width: widthInput,
                          margin: 0,
                          fontVariant: 'tabular-nums',
                          listStyle: 'none',
                          fontFeatureSettings: 'tnum',
                          position: 'relative',
                          display: 'inline-block',
                          minWidth: 0,
                          padding: '4px 11px',
                          color: 'rgba(0,0,0,.85)',
                          fontSize: 14,
                          lineHeight: 1.5715,
                          backgroundColor: '#fff',
                          backgroundImage: 'none',
                          border: '1px solid #d9d9d9',
                          transition: 'all .3s',
                        }}
                        onChange={() => {
                          setValue(moment().unix().toString());
                        }}
                      />
                      // <Input
                      //   key={idx}
                      //   size="middle"
                      //   placeholder=""
                      //   id={`${indexAttempt}-national-language/white-paper-${index}-${idx}`}
                      //   style={{ borderRadius: '0.3rem', width: widthInput }}
                      //   onChange={() => {
                      //     setValue(moment().unix().toString());
                      //   }}
                      // />
                    ))}
                  </Space>
                </div>
              </div>
            );
          })}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default memo(MultichoiceTextDrag);
