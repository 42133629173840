import { Space, Spin } from 'antd';
import { useState } from 'react';
import { useDidUpdateEffect } from 'utils/HookUtils';
import ConfirmPassword from './ConfirmPassword';

type Props = {
  tipSpin?: string;
  isSubSpin?: boolean;
  fn?: () => void;
};

const Loading: React.FC<Props> = ({
  tipSpin = 'Đang tải',
  isSubSpin = false,
  fn,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState(10);
  useDidUpdateEffect(() => {
    if (count === 0 && fn) {
      setIsModalOpen(true);
    }
  }, [count]);
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ConfirmPassword
        fn={fn}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={() => {
          setIsModalOpen(false);
          setCount(10);
        }}
      />
      {!isModalOpen && (
        <Spin tip={tipSpin} size="large">
          <div className="content" />
        </Spin>
      )}
      {isSubSpin && !isModalOpen && (
        <div
          onClick={() => count > 0 && setCount((prev) => prev - 1)}
          style={{
            fontSize: 14,
            lineHeight: 1.5715,
            color: '#ff4d4f',
            textAlign: 'center',
            width: '100%',
            marginTop: '3vh',
            cursor: 'pointer',
            fontWeight: 600,
          }}
        >
          Bấm vào TẠI ĐÂY {count} lần để thi lại!
        </div>
      )}
    </Space>
  );
};

export default Loading;
