import { Button } from 'antd';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import type { DragSourceMonitor } from 'react-dnd';
import { useDrag } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';

import { isMobile } from 'utils';

export interface SourceBoxProps {
  suggest: { id: number; name: string };
  isRoadSign: boolean;
}

export const SourceBox: FC<SourceBoxProps> = memo(function SourceBox({
  suggest = { id: -1, name: '' },
  isRoadSign,
}) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: suggest.name,
      item: suggest,
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [suggest]
  );

  const containerStyle = useMemo(
    () => ({
      opacity: isDragging ? 0.4 : 1,
      cursor: 'move',
    }),
    [isDragging]
  );

  return (
    <>
      {isMobile() && <MyPreview isRoadSign={isRoadSign} />}
      {isRoadSign ? (
        <div
          style={{
            border: '0.5px solid #eee',
            borderRadius: '0.3rem',
            ...containerStyle,
          }}
          ref={drag}
          role="SourceBox"
          className="button-shadow"
        >
          <img
            style={{
              width: isMobile() ? '16.5vw' : 100,
              aspectRatio: 1 / 1,
              borderRadius: '0.3rem',
            }}
            src={suggest.name}
          />
        </div>
      ) : (
        <Button
          style={{
            borderRadius: '2rem',
            width: isMobile() ? '20vw' : 100,
            ...containerStyle,
          }}
          ref={drag}
          role="SourceBox"
          className="button-shadow"
        >
          {suggest.name}
        </Button>
      )}
    </>
  );
});

const MyPreview: React.FC<{ isRoadSign: boolean }> = ({ isRoadSign }) => {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { item, style }: any = preview;
  return (
    <div style={style}>
      {isRoadSign ? (
        <div
          style={{
            border: '0.5px solid #eee',
            borderRadius: '0.3rem',
          }}
          className="button-shadow"
        >
          <img
            style={{
              width: isMobile() ? '16.5vw' : 100,
              aspectRatio: 1 / 1,
              borderRadius: '0.3rem',
            }}
            src={item.name}
          />
        </div>
      ) : (
        <Button
          style={{
            borderRadius: '2rem',
            width: isMobile() ? '20vw' : 100,
          }}
          role="SourceBox"
          className="button-shadow"
        >
          {item.name}
        </Button>
      )}
    </div>
  );
};
