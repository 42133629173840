import React from 'react';

import successUrl from 'assets/img/success.png';
import { COLORS } from 'style';
import { Typography } from 'antd';
const { Text } = Typography;
const Success: React.FC = () => {
  return (
    <div
      style={{ minHeight: '90vh' }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <img
        style={{
          width: 160,
          aspectRatio: 1 / 1,
          borderRadius: '0.3rem',
        }}
        src={successUrl}
      />
      <Text
        style={{
          color: COLORS.TEXT.success,
          fontSize: '0.8rem',
          marginTop: 16,
          marginBottom: 14,
          fontWeight: 800,
          textAlign: 'center',
        }}
      >
        Chúc mừng bạn đã giải đúng KHÓA mật thư
      </Text>
    </div>
  );
};

export default Success;
