const TEXT = {
  primary: '#093B86',
  yellowGrey: '#6E6601',
  pink: '#C9308D',
  organe: '#C9771B',
  green: '#4DA81A',
  brown: '#B8704C',
  blue: '##37B0F4',
  fail: '#EB1C24',
  success: '#389E0D',
  red: '#CF1322',
};

const HEADER = {
  yellow: '#FCC829',
  pink: '#C9308D',
  organe: '#FB9522',
  green: '#4DA81A',
  brown: '#B8704C',
  blue: '#37B0F4',
};

export default {
  TEXT,
  HEADER,
};
