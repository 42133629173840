import { Button, Input, Modal, Space, Typography, notification } from 'antd';
import React, { memo, useState } from 'react';
import { KeyOutlined } from '@ant-design/icons';

import { COLORS } from 'style';
import { isMobile } from 'utils';

const { Title } = Typography;

type Props = {
  isModalOpen: boolean;
  handleCancel: () => void;
  setIsModalOpen: (_: boolean) => void;
  fn?: () => void;
};

const ModalConfirmPassword: React.FunctionComponent<Props> = ({
  isModalOpen,
  setIsModalOpen,
  handleCancel,
  fn,
}: Props) => {
  const [confirmPassword, setConfirmPassword] = useState('');
  const checkValid = (): void => {
    //
    const password = atob(JSON.parse(localStorage.getItem('password') ?? '""'));
    if (password === confirmPassword) {
      !!fn && fn();
      setIsModalOpen(false);
    } else {
      handleCancel();
      notification.error({
        message: 'Mật khẩu không hợp lệ!',
      });
    }
  };

  return (
    <Modal
      style={{ maxWidth: isMobile() ? '90vw' : 420, zIndex: 999999999 }}
      bodyStyle={{
        borderRadius: '0.3rem',
        padding: '1rem',
      }}
      maskStyle={{
        borderRadius: '0.3rem',
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <Space
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
        size={'middle'}
        direction="vertical"
      >
        <Title
          style={{
            textAlign: 'center',
            color: COLORS.TEXT.yellowGrey,
            marginBottom: 0,
          }}
          className="text-center"
          level={5}
        >
          Nhập mật khẩu xác nhận thi lại
        </Title>

        <Input.Password
          placeholder="Nhập mật khẩu"
          autoComplete="off"
          style={{
            borderRadius: '0.5rem',
            maxWidth: 200,
          }}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          visibilityToggle={{
            visible: false,
          }}
          prefix={<KeyOutlined style={{ fontSize: 18 }} />}
        />

        <div className="d-flex justify-content-center">
          <Button
            onClick={handleCancel}
            className="button-shadow"
            style={{
              height: 26,
              color: '#6E6601',
              fontSize: '0.8rem',
              borderRadius: '0.5rem',
              margin: '0px 8px',
              fontWeight: '700',
            }}
            type={'ghost'}
          >
            Huỷ
          </Button>
          <Button
            onClick={checkValid}
            className="button-shadow"
            style={{
              background:
                'linear-gradient(180deg, rgba(255,228,146,1) 0%, rgba(250,195,0,1) 50%)',
              height: 26,
              color: '#6E6601',
              fontSize: '0.8rem',
              borderRadius: '0.5rem',
              margin: '0px 8px',
              fontWeight: '700',
            }}
            type={'ghost'}
          >
            Xác nhận
          </Button>
        </div>
      </Space>
    </Modal>
  );
};

export default memo(ModalConfirmPassword);
