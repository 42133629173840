import accountReducer from 'features/accountSlice';
import sectionGroupSlice from 'features/sectionGroupSlice';
import examQuestionSlice from 'features/examQuestionSlice';
import examAttemptSlice from 'features/examAttemptSlice';

export const reducer = {
  accountReducer,
  sectionGroupSlice,
  examQuestionSlice,
  examAttemptSlice,
};
