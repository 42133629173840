import React, { memo } from 'react';
import { Progress, Radio, RadioChangeEvent, Space } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { Attempt, OptionQuestion } from 'type';

import { COLORS } from 'style';
type Props = {
  indexAttempt: number;
  attempt: Attempt[];
  content: string;
  options: OptionQuestion[];
  value: string;
  setValue: (_: string) => void;
};

const SingleChoice: React.FC<Props> = ({
  indexAttempt,
  attempt,
  content,
  options,
  value,
  setValue,
}) => {
  const handleChange = (event: RadioChangeEvent): void => {
    setValue(event.target.value.toString());
  };

  return (
    <>
      <div
        style={{
          minWidth: 300,
          maxWidth: 400,
          margin: '0px auto',
        }}
      >
        <Progress
          className="custom-progress"
          percent={
            attempt.length === 0
              ? 0
              : ((indexAttempt + 1) * 100) / attempt.length
          }
          status="active"
          style={{}}
          format={(percent) =>
            `${Math.floor(((percent ?? 0) * attempt.length) / 100)
              .toString()
              .padStart(2, '0')}/${attempt.length.toString().padStart(2, '0')}`
          }
        />
      </div>
      <AnimatePresence mode="wait">
        <motion.div
          key={indexAttempt.toString()}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div>
            <div style={{ paddingTop: 12 }}>
              <span
                style={{
                  color: COLORS.TEXT.yellowGrey,
                  fontWeight: '600',
                  fontSize: '0.9rem',
                  marginBottom: 12,
                }}
                dangerouslySetInnerHTML={{
                  __html: `Câu ${
                    indexAttempt + 1
                  }:&nbsp;<span style="color: #595959;font-size: 1rem;font-weight: 400">${content}</span>`,
                }}
              />
            </div>
            <div style={{ paddingTop: 12 }}>
              <p
                style={{
                  color: COLORS.TEXT.yellowGrey,
                  fontWeight: '600',
                  fontSize: '0.9rem',
                }}
              >
                Đáp án:
              </p>
              <Radio.Group onChange={handleChange} value={value}>
                <Space direction="vertical" size={'middle'}>
                  {options.map((item, index) => (
                    <Radio value={item.code.toString()} key={index}>
                      {item.content}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default memo(SingleChoice);
