import {
  completeExamAttemptFail,
  completeExamAttemptSuccess,
  completeLtsExamAttemptFail,
  completeLtsExamAttemptSuccess,
  createExamAttemptFail,
  createExamAttemptSuccess,
  createLtsExamAttemptFail,
  createLtsExamAttemptSuccess,
  getStatusExamQuestionFail,
  getStatusExamQuestionSuccess,
  completeQuestionFail,
  completeQuestionSuccess,
} from 'features/examAttemptSlice';
import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import { GET, GET_FILE, POST } from 'services/ServiceBase';
import ServiceURL from 'services/ServiceURL';
import { ActionType, Attempt, ExamAttempt, ResultSubmit } from 'type';
import _ from 'lodash';

export function* createExamAttempt(data: ActionType): any {
  const url = ServiceURL.examAttempts;

  const { callback, body } = data.payload;
  try {
    const res = yield call(POST, url, body);

    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
      yield put(createExamAttemptFail());
    } else {
      yield put(createExamAttemptSuccess(_.get(res, 'data', {})));
      callback.success(_.get(res, 'data', {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? '');
    yield put(createExamAttemptFail());
  }
}

export function* createLtsExamAttempt(data: ActionType): any {
  const { callback, other } = data.payload;
  const url = ServiceURL.examAttempts;

  const ltsCreateExamAttempt = (other ?? []).map((id: string) => {
    return call(POST, url, {
      examQuestion: id,
    });
  });
  try {
    const res = yield all(ltsCreateExamAttempt);
    const resSort = (other ?? []).map((id: string) => {
      return res.find(
        (item: { data: ExamAttempt }) => item.data.examQuestion === id
      )?.data;
    });
    const urls: string[] = resSort
      .map((r: ExamAttempt): (string[] | string)[] => {
        return r.attempt.map((att: Attempt | Attempt[]): string[] | string => {
          if (Array.isArray(att)) {
            return att.map((a): string => {
              return a.question?.othersContent?.video?.url ?? '';
            });
          }
          return att.question?.othersContent?.video?.url ?? '';
        });
      })
      .reduce((prev: string[], curr: (string | string[])[]) => {
        const tmp = curr.reduce(
          (p: string[], c: string | string[]): string[] => {
            if (Array.isArray(c)) {
              return [...p, ...c];
            }
            return [...p, c];
          },
          []
        );
        return [...prev, ...tmp];
      }, [])
      .filter((item: string) => item !== '');

    yield downloadVideosToCache(urls);

    yield put(
      createLtsExamAttemptSuccess(
        resSort.filter((item: ExamAttempt | undefined) => item !== undefined)
      )
    );

    callback.success(
      resSort.filter((item: ExamAttempt | undefined) => item !== undefined)
    );
  } catch (error) {
    callback.failed('Phần thi lỗi');
    yield put(createLtsExamAttemptFail());
  }
}

export function* completeExamAttempt(data: ActionType): any {
  const { callback, body, param } = data.payload;
  const url = `${ServiceURL.examAttempts}/${param}`;
  try {
    const res = yield call(POST, url, body);

    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
      yield put(completeExamAttemptFail());
    } else {
      yield put(completeExamAttemptSuccess(_.get(res, 'data', {})));
      callback.success(_.get(res, 'data', {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? '');
    yield put(completeExamAttemptFail());
  }
}

export function* completeQuestion(data: ActionType): any {
  const { callback, body, param } = data.payload;
  const url = `${ServiceURL.examAttempts}/part/${param}`;
  try {
    const res = yield call(POST, url, body);

    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
      yield put(completeQuestionFail());
    } else {
      yield put(completeQuestionSuccess(_.get(res, 'data', {})));
      callback.success(_.get(res, 'data', {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? '');
    yield put(completeQuestionFail());
  }
}

export function* completeLtsExamAttempt(data: ActionType): any {
  const { callback, other } = data.payload;
  const ltsCompleteExamAttempt = (other ?? []).map(
    (item: { param: string; body: any }) => {
      const url = `${ServiceURL.examAttempts}/${item.param}`;
      return call(POST, url, { attempt: item.body });
    }
  );
  try {
    const res = yield all(ltsCompleteExamAttempt);

    yield put(
      completeLtsExamAttemptSuccess(
        res.map((item: { data: ResultSubmit }) => item.data)
      )
    );
    callback.success(res.map((item: { data: ResultSubmit }) => item.data));
  } catch (error) {
    // callback.failed('Gửi kết quả lỗi');
    yield put(completeLtsExamAttemptFail());
  }
}

export function* getStatusExamQuestion(data: ActionType): any {
  const { callback, other } = data.payload;
  const ltsStatus = (other ?? []).map((item: string) => {
    const url = `${ServiceURL.examAttempts}/status/${item}`;
    return call(GET, url);
  });
  try {
    const res = yield all(ltsStatus);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
      yield put(getStatusExamQuestionFail());
    } else {
      yield put(getStatusExamQuestionSuccess(true));
      callback.success(res.map((item: { data: ResultSubmit }) => item.data));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? '');
    yield put(getStatusExamQuestionFail());
  }
}

export function* downloadVideosToCache(urls: string[]): any {
  const ltsVideos = urls.map((url) => call(GET_FILE, url, ''));
  const res = yield all(ltsVideos);
  yield Promise.all(
    res.map(async (item: { data: any; config: { url: string } }) => {
      try {
        const cacheName = 'static_cache';
        const cache = await caches.open(cacheName);
        // console.log('doing: ', item.config.url);
        await cache.put(item.config.url, new Response(item.data));
        // console.log('done');
      } catch (error) {
        //
        // console.log('error save to cache: ', error);
      }
    })
  );
  // console.log('success download');
}

export function* examAttemptSaga(): any {
  yield takeLatest('examAttempt/createExamAttemptRequest', createExamAttempt);
  yield takeLatest(
    'examAttempt/createLtsExamAttemptRequest',
    createLtsExamAttempt
  );
  yield takeLatest(
    'examAttempt/completeExamAttemptRequest',
    completeExamAttempt
  );
  yield takeLatest(
    'examAttempt/completeLtsExamAttemptRequest',
    completeLtsExamAttempt
  );
  yield takeEvery(
    'examAttempt/getStatusExamQuestionRequest',
    getStatusExamQuestion
  );
  yield takeLatest('examAttempt/completeQuestionRequest', completeQuestion);
}
