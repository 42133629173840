import React, { memo, useEffect, useRef, useState } from 'react';
import { Space, notification } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { COLORS } from 'style';
import { Attempt, OptionQuestion } from 'type';
import { KEYS } from 'const';

type Props = {
  videoUrl: string;
  content: string;
  options: OptionQuestion[];
  indexAttempt: number;
  answerLength: number;
  setValue: (_: string) => void;
  isLoaded: boolean;
  setIsLoaded: (_: boolean) => void;
  isKeepOption: boolean;
  others: Attempt[];
  keyStorage: string;
  fn?: () => void;
};

const Semaphore: React.FC<Props> = ({
  videoUrl,
  content,
  // options,
  indexAttempt,
  answerLength,
  setValue,
  isLoaded,
  setIsLoaded,
  // isKeepOption,
  others,
  keyStorage,
  fn,
}) => {
  const widthInput = 72;

  const ref = useRef<HTMLVideoElement | null>(null);
  const [videoFromCache, setVideoFromCache] = useState<string | null>(null);

  const getVideoCache = async (): Promise<any> => {
    try {
      const cacheStorage = await caches.open('static_cache');
      const cachedResponse = await cacheStorage.match(videoUrl);

      if (!cachedResponse || !cachedResponse.ok) {
        playVideo(videoUrl);
        notification.error({
          message: `Lỗi tải video lên. Đang chạy video trực tiếp!`,
        });
        return;
      }
      const arrayBuffer = await cachedResponse.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: 'video/mp4' });

      playVideo(URL.createObjectURL(blob));
    } catch (error) {
      playVideo(videoUrl);
      notification.error({
        message: `Lỗi tải video lên: ${JSON.stringify(
          error
        )}. Đang chạy video trực tiếp!!`,
      });
      // console.log('error: ', error);
    }
  };

  const playVideo = (url: string): void => {
    setVideoFromCache(url);
    if (ref.current) {
      ref.current.setAttribute('autoplay', 'autoplay');
      ref.current.loop = false;
      ref.current.load();
    }
  };

  const handleLoadedVideo = (): void => {
    const time = localStorage.getItem(`${KEYS.currentTime}-${keyStorage}`);
    const currentTime = time ? Math.floor(JSON.parse(time)) : 0;
    if (!ref.current) return;
    if (_.get(ref.current, 'fastSeek', undefined)) {
      ref.current.fastSeek(
        currentTime <= ref.current.duration ? currentTime : 0
      );
      // if (ref.current.duration - 1 <= currentTime) {
      //   ref.current.pause();
      // }
    } else {
      ref.current.currentTime =
        currentTime - 1 <= ref.current.duration ? currentTime : 0;
      // if (ref.current.duration - 1 <= currentTime) {
      //   ref.current.pause();
      // }
    }
  };

  useEffect(() => {
    if (ref.current) {
      getVideoCache();
    }
  }, [ref.current]);

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('unload', handleTabClosing);
    };
  }, []);

  const handleTabClosing = (): void => {
    if (!ref.current) return;
    localStorage.setItem(
      `${KEYS.currentTime}-${keyStorage}`,
      JSON.stringify(ref.current.currentTime)
    );
  };

  const handleOnEnded = (): void => {
    if (!ref.current) return;
    localStorage.setItem(
      `${KEYS.currentTime}-${keyStorage}`,
      JSON.stringify(ref.current.currentTime)
    );
    setIsLoaded(true);
    setTimeout(() => {
      !!fn && fn();
    }, 2000);
  };

  return (
    <div style={{ padding: 12, paddingBottom: '20vh' }}>
      <div style={{ marginBottom: 16 }}>
        <span
          style={{
            color: COLORS.TEXT.yellowGrey,
            fontWeight: '600',
            fontSize: '0.9rem',
          }}
          dangerouslySetInnerHTML={{
            __html: `Câu hỏi:&nbsp;<span
            style="
              color: ${COLORS.TEXT.pink};
              font-weight: '600';
              font-size: '1rem';
              
            "
          >
            Vui lòng xem video để trả lời câu hỏi
          </span>`,
          }}
        />
      </div>

      <video
        ref={ref}
        style={{
          aspectRatio: 16 / 9,
          marginBottom: 12,
        }}
        onEnded={() => {
          handleOnEnded();
        }}
        onLoadedData={() => {
          handleLoadedVideo();
        }}
        width="100%"
        playsInline
        preload=""
        muted
        loop={false}
      >
        <source
          src={videoFromCache ? videoFromCache : videoUrl}
          type="video/mp4"
        />
      </video>

      {isLoaded && (
        <>
          <span
            style={{
              color: COLORS.TEXT.yellowGrey,
              fontWeight: '600',
              fontSize: '1rem',
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div style={{ paddingTop: 12 }}>
            <div className="d-flex justify-content-center">
              <Space
                size={8}
                style={{
                  justifyContent: 'center',
                  maxWidth: widthInput * 6,
                }}
                wrap
              >
                {new Array(answerLength).fill(null).map((_, index) => (
                  <input
                    key={index}
                    placeholder=""
                    id={`${indexAttempt}-national-language/white-paper-${index}`}
                    style={{
                      borderRadius: '0.3rem',
                      width: widthInput,
                      margin: 0,
                      fontVariant: 'tabular-nums',
                      listStyle: 'none',
                      fontFeatureSettings: 'tnum',
                      position: 'relative',
                      display: 'inline-block',

                      minWidth: 0,
                      padding: '4px 11px',
                      color: 'rgba(0,0,0,.85)',
                      fontSize: 14,
                      lineHeight: 1.5715,
                      backgroundColor: '#fff',
                      backgroundImage: 'none',
                      border: '1px solid #d9d9d9',

                      transition: 'all .3s',
                    }}
                    onChange={() => {
                      setValue(moment().unix().toString());
                    }}
                  />
                  // <Input
                  //   key={index}
                  //   size="middle"
                  //   placeholder=""
                  //   id={`${indexAttempt}-national-language/white-paper-${index}`}
                  //   style={{ borderRadius: '0.3rem', width: widthInput }}
                  //   onChange={() => {
                  //     setValue(moment().unix().toString());
                  //   }}
                  // />
                ))}
              </Space>
            </div>
          </div>
          {others.map((item, index) => {
            const length = _.get(item, `answerLength`, 0);
            const contentOther = _.get(item, 'question.content', '');
            const contentConvert = contentOther.substring(
              contentOther.indexOf('**') + 2,
              contentOther.lastIndexOf('**')
            );
            return (
              <div key={index} style={{ paddingTop: 12 }}>
                <span
                  style={{
                    color: COLORS.TEXT.yellowGrey,
                    fontWeight: '600',
                    fontSize: '1rem',
                  }}
                >
                  {contentConvert}
                </span>
                <div className="d-flex justify-content-center">
                  <Space
                    size={8}
                    style={{
                      justifyContent: 'center',
                      maxWidth: widthInput * 6,
                    }}
                    wrap
                  >
                    {new Array(length).fill(null).map((_, idx) => (
                      <input
                        key={index}
                        placeholder=""
                        id={`${indexAttempt}-national-language/white-paper-${index}-${idx}`}
                        style={{
                          borderRadius: '0.3rem',
                          width: widthInput,
                          margin: 0,
                          fontVariant: 'tabular-nums',
                          listStyle: 'none',
                          fontFeatureSettings: 'tnum',
                          position: 'relative',
                          display: 'inline-block',

                          minWidth: 0,
                          padding: '4px 11px',
                          color: 'rgba(0,0,0,.85)',
                          fontSize: 14,
                          lineHeight: 1.5715,
                          backgroundColor: '#fff',
                          backgroundImage: 'none',
                          border: '1px solid #d9d9d9',

                          transition: 'all .3s',
                        }}
                        onChange={() => {
                          setValue(moment().unix().toString());
                        }}
                      />
                      // <Input
                      //   key={idx}
                      //   size="middle"
                      //   placeholder=""
                      //   id={`${indexAttempt}-national-language/white-paper-${index}-${idx}`}
                      //   style={{ borderRadius: '0.3rem', width: widthInput }}
                      //   onChange={() => {
                      //     setValue(moment().unix().toString());
                      //   }}
                      // />
                    ))}
                  </Space>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default memo(Semaphore);
