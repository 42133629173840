import { createSlice } from '@reduxjs/toolkit';

export const sectionGroup = createSlice({
  name: 'sectionGroup',
  initialState: {
    gettingSectionGroups: false,
    gettingSectionGroupsWithInfo: false,
    sectionGroups: {},
    sectionGroupsWithInfo: {},
  },
  reducers: {
    // eslint-disable-next-line
    getSectionGroupsRequest: (state, action) => {
      state.gettingSectionGroups = true;
    },
    getSectionGroupsSuccess: (state, action) => {
      state.gettingSectionGroups = false;
      state.sectionGroups = action.payload;
    },
    getSectionGroupsFail: (state) => {
      state.gettingSectionGroups = false;
    },
    // eslint-disable-next-line
    getSectionGroupsWithInfoRequest: (state, action) => {
      state.gettingSectionGroupsWithInfo = true;
    },
    getSectionGroupsWithInfoSuccess: (state, action) => {
      state.gettingSectionGroupsWithInfo = false;
      state.sectionGroupsWithInfo = action.payload;
    },
    getSectionGroupsWithInfoFail: (state) => {
      state.gettingSectionGroupsWithInfo = false;
    },
  },
});
export const {
  getSectionGroupsRequest,
  getSectionGroupsSuccess,
  getSectionGroupsFail,
  getSectionGroupsWithInfoRequest,
  getSectionGroupsWithInfoSuccess,
  getSectionGroupsWithInfoFail,
} = sectionGroup.actions;
export default sectionGroup.reducer;
