import { FC, memo, useCallback, useEffect } from 'react';
import { LeftOutlined } from '@ant-design/icons';

import { COLORS } from 'style';
import { Button } from 'antd';
import { useDidUpdateEffect } from 'utils/HookUtils';

type Props = {
  timeDo: number;
  bg?: string;
  title?: string;
  color?: string;
  isShowBack: boolean;
  indexAttempt: number;
  timeLeft: number;
  setTimeLeft: (_: number) => void;
  handleTimeUp: () => void;
  handleBackAttempt: () => void;
  setRemainTime: (_: number) => void;
};

const CountDown: FC<Props> = ({
  timeDo,
  title = '',
  bg = COLORS.HEADER.yellow,
  color = COLORS.TEXT.yellowGrey,
  isShowBack,
  indexAttempt,
  timeLeft,
  handleTimeUp,
  handleBackAttempt,
  setRemainTime,
  setTimeLeft,
}) => {
  useDidUpdateEffect(() => {
    if (timeLeft <= 0) {
      setRemainTime(0);
      handleTimeUp();
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(timeDo);
  }, [timeDo, indexAttempt]);

  const minutes = Math.floor(timeLeft / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeLeft % 60).toString().padStart(2, '0');

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('unload', handleTabClosing);
    };
  }, [timeLeft]);

  const handleTabClosing = useCallback((): void => {
    setRemainTime(timeLeft);
  }, [timeLeft]);

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{
        fontSize: '1.2rem',
        background: bg,
        color,
        padding: '0.8rem',
        fontWeight: '600',
        position: 'fixed',
        width: '100%',
        maxWidth: 1024,
        zIndex: 1,
      }}
    >
      {isShowBack && (
        <Button onClick={handleBackAttempt} type="text">
          <LeftOutlined />
        </Button>
      )}
      <div style={{ width: '80%' }}>{title}</div>
      <span
        style={{
          fontWeight: '600',
          color: '#CF1322',
          fontSize: 26,
        }}
      >
        {minutes + ':' + seconds}
      </span>
    </div>
  );
};

export default memo(CountDown);
