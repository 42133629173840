import React from 'react';
import { Button, Typography, Row, Col, Tooltip } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';

import { COLORS } from 'style';

import logo from 'assets/img/logo.png';
import headerYellow from 'assets/img/header_yellow.png';
import { MAX_WIDTH } from 'const';

const { Text } = Typography;

type Props = {
  src?: string | undefined;
  bg?: string;
  children: JSX.Element;
};

const ClientHeader: React.FunctionComponent<Props> = ({
  src = headerYellow,
  bg = COLORS.HEADER.yellow,
  children,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const contestant = JSON.parse(localStorage.getItem('contestant') ?? '""');

  return (
    <div
      style={{
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      <Row
        justify={'center'}
        style={{
          backgroundColor: bg,
          height: 70,
          position: 'fixed',
          zIndex: 2,
          width: '100%',
        }}
        className="align-items-center header-shadow"
      >
        <img
          style={{ position: 'absolute' }}
          src={src}
          width={'auto'}
          height={70}
          alt="logo"
        />

        <Col
          className="d-flex justify-content-center align-items-center"
          xs={4}
          lg={2}
          md={3}
        >
          <Link to={'/'}>
            <img src={logo} width={50} height={50} alt="logo" />
          </Link>
        </Col>

        <Col xs={16} lg={20} md={18}>
          <Text
            className="text-shadow"
            style={{
              color:
                bg === COLORS.HEADER.yellow ? COLORS.TEXT.yellowGrey : '#fff',
              textTransform: 'uppercase',
              fontWeight: 500,
              marginBottom: 0,
              fontSize: '1.2rem',
            }}
          >
            {contestant?.name ?? ''}
          </Text>
          <br />
          <Text
            className="text-shadow"
            style={{
              fontSize: '0.9rem',
              color:
                bg === COLORS.HEADER.yellow ? COLORS.TEXT.yellowGrey : '#fff',
            }}
          >
            {contestant?.phone}
          </Text>
        </Col>
        <Col xs={4} lg={2} md={3}>
          <Tooltip title="Đăng xuất">
            <Button
              style={{ border: 'none' }}
              shape="circle"
              type="ghost"
              onClick={() => {
                localStorage.removeItem('access-token');
                localStorage.removeItem('refresh-token');
                localStorage.removeItem('contestant');
                // contestant
                history.push('/auth/login');
              }}
              icon={
                <ExportOutlined
                  style={{
                    fontSize: '1.6rem',
                    color:
                      bg === COLORS.HEADER.yellow
                        ? COLORS.TEXT.yellowGrey
                        : '#fff',
                  }}
                />
              }
            ></Button>
          </Tooltip>
        </Col>
      </Row>

      <div
        style={{
          maxWidth: location.pathname.includes('/home') ? '100%' : MAX_WIDTH,
          margin: '70px auto 0px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ClientHeader;
