export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_URL_IMAGE;

const login = 'auth/login';
const register = 'users/reg';
const sectionGroups = 'sectionGroups';
const examQuestions = 'examQuestions';
const examAttempts = 'examAttempts';

export default {
  BASE_URL,
  login,
  register,
  sectionGroups,
  examQuestions,
  examAttempts,
};
