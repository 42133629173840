import React from 'react';

import failedUrl from 'assets/img/failed.png';
import { Typography } from 'antd';
import { COLORS } from 'style';
const { Text } = Typography;
const Failed: React.FC = () => {
  return (
    <div
      style={{ minHeight: '90vh' }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <img
        style={{
          width: 160,
          aspectRatio: 1 / 1,
          borderRadius: '0.3rem',
        }}
        src={failedUrl}
      />
      <Text
        style={{
          color: COLORS.TEXT.red,
          fontSize: '0.8rem',
          marginTop: 16,
          marginBottom: 14,
          fontWeight: 800,
        }}
      >
        Rất tiếc bạn chưa giải được KHÓA mật thư
      </Text>
    </div>
  );
};

export default Failed;
