import { createSlice } from '@reduxjs/toolkit';

export const examQuestion = createSlice({
  name: 'examQuestion',
  initialState: {
    gettingExamQuestions: false,
    examQuestions: {},
  },
  reducers: {
    // eslint-disable-next-line
    getExamQuestionsRequest: (state, action) => {
      state.gettingExamQuestions = true;
    },
    getExamQuestionsSuccess: (state, action) => {
      state.gettingExamQuestions = false;
      state.examQuestions = action.payload;
    },
    getExamQuestionsFail: (state) => {
      state.gettingExamQuestions = false;
    },
  },
});
export const {
  getExamQuestionsRequest,
  getExamQuestionsSuccess,
  getExamQuestionsFail,
} = examQuestion.actions;
export default examQuestion.reducer;
