export const TOPIC_STATUS = {
  notOpenYet: 0, // chua mo
  openning: 1, // dang mo
  closed: 2, // da dong
};

export const MAX_WIDTH = 1024;

export const PROVINCES = [
  { label: 'An Giang', value: 0 },
  { label: 'Bà Rịa Vũng Tàu', value: 1 },
  { label: 'Bình Dương', value: 2 },
  { label: 'Bình Phước', value: 3 },
  { label: 'Bình Thuận', value: 4 },
  { label: 'Bình Định ', value: 5 },
  { label: 'Bạc Liêu', value: 6 },
  { label: 'Bắc Giang', value: 7 },
  { label: 'Bắc Kạn', value: 8 },
  { label: 'Bắc Ninh', value: 9 },
  { label: 'Bến Tre', value: 10 },
  { label: 'Cao Bằng', value: 11 },
  { label: 'Cà Mau', value: 12 },
  { label: 'Cần Thơ', value: 13 },
  { label: 'Gia Lai', value: 14 },
  { label: 'Hà Giang', value: 15 },
  { label: 'Hà Nam', value: 16 },
  { label: 'Hà Nội', value: 17 },
  { label: 'Hà Tĩnh', value: 18 },
  { label: 'Hòa Bình', value: 19 },
  { label: 'Hưng Yên', value: 20 },
  { label: 'Hải Dương', value: 21 },
  { label: 'Hải Phòng', value: 22 },
  { label: 'Hậu Giang', value: 23 },
  { label: 'Khánh Hòa', value: 24 },
  { label: 'Kiên Giang', value: 25 },
  { label: 'Kon Tum', value: 26 },
  { label: 'Lai Châu', value: 27 },
  { label: 'Long An', value: 28 },
  { label: 'Lào Cai', value: 29 },
  { label: 'Lâm Đồng', value: 30 },
  { label: 'Lạng Sơn', value: 31 },
  { label: 'Nam Định', value: 32 },
  { label: 'Nghệ An', value: 33 },
  { label: 'Ninh Bình', value: 34 },
  { label: 'Ninh Thuận', value: 35 },
  { label: 'Phú Thọ', value: 36 },
  { label: 'Phú Yên', value: 37 },
  { label: 'Quảng Bình', value: 38 },
  { label: 'Quảng Nam', value: 39 },
  { label: 'Quảng Ngãi', value: 40 },
  { label: 'Quảng Ninh', value: 41 },
  { label: 'Quảng Trị', value: 42 },
  { label: 'Sóc Trăng', value: 43 },
  { label: 'Sơn La', value: 44 },
  { label: 'TP. Hồ Chí Minh', value: 45 },
  { label: 'Thanh Hóa', value: 46 },
  { label: 'Thái Bình', value: 47 },
  { label: 'Thái Nguyên', value: 48 },
  { label: 'Thừa Thiên Huế', value: 49 },
  { label: 'Tiền Giang', value: 50 },
  { label: 'Trà Vinh', value: 51 },
  { label: 'Tuyên Quang', value: 52 },
  { label: 'Tây Ninh', value: 53 },
  { label: 'Vĩnh Long', value: 54 },
  { label: 'Vĩnh Phúc', value: 55 },
  { label: 'Yên Bái', value: 56 },
  { label: 'Điện Biên', value: 57 },
  { label: 'Đà Nẵng', value: 58 },
  { label: 'Đắk Lắk', value: 59 },
  { label: 'Đắk Nông', value: 60 },
  { label: 'Đồng Nai', value: 61 },
  { label: 'Đồng Tháp', value: 62 },
];

export const MAJOR = [
  { value: 0, label: 'Khoa Cơ khí' },
  { value: 1, label: 'Khoa Công nghệ Hoá' },
  { value: 2, label: 'Khoa Công nghệ may & TKTT' },
  { value: 3, label: 'Khoa Công nghệ Ô tô' },
  { value: 4, label: 'Khoa Công nghệ thông tin' },
  { value: 5, label: 'Khoa Điện' },
  { value: 6, label: 'Khoa Điện tử' },
  { value: 7, label: 'Khoa Kế toán - Kiểm toán' },
  { value: 8, label: 'Trường Ngoại ngữ - Du lịch' },
  { value: 9, label: 'Khoa Quản lý kinh doanh' },
  { value: 10, label: 'Trung tâm cơ khí' },
  { value: 11, label: 'Trung tâm Việt Nhật' },
  { value: 12, label: 'TT CN Ô tô và ĐTLX' },
];

export const KEYS = {
  ltsExamAttempts: 'ltsExamAttempts',
  section: 'section',
  page: 'page',
  currentTime: 'currentTime',
};
export const ANSWER_TYPE = {
  singleChoice: 'single_choice',
  multiChoiceTextDrag: 'multi_choice_text_drag',
  multiChoiceImgDrag: 'multi_choice_img_drag',
};

export const QUESTION_TYPE = {
  video: 'video',
  text: 'text',
};
