import {
  getExamQuestionsFail,
  getExamQuestionsSuccess,
} from 'features/examQuestionSlice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GET } from 'services/ServiceBase';
import ServiceURL from 'services/ServiceURL';
import { ActionType } from 'type';
import _ from 'lodash';

export function* getExamQuestions(data: ActionType): any {
  const { callback, query } = data.payload;
  const url = `${ServiceURL.examQuestions}?${query}`;
  try {
    const res = yield call(GET, url);

    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
      yield put(getExamQuestionsFail());
    } else {
      yield put(getExamQuestionsSuccess(_.get(res, 'data', [])));
      callback.success(_.get(res, 'data', []));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? '');
    yield put(getExamQuestionsFail());
  }
}

export function* examQuestionSaga(): any {
  yield takeLatest('examQuestion/getExamQuestionsRequest', getExamQuestions);
}
