import React, { memo, useEffect, useMemo, useState } from 'react';
// import { notification } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import queryString from 'query-string';
import { notification } from 'antd';

import _ from 'lodash';
import { COLORS } from 'style';
import ButtonPrimary from 'pages/components/ButtonPrimary';
import { Attempt, ExamAttempt, OptionQuestion, PayloadType } from 'type';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CountDown from './components/CountDown';
import moment from 'moment';
import ReceiveResult from './components/ReceiveResult';
import Loading from 'pages/components/Loading';
import { ANSWER_TYPE, KEYS, QUESTION_TYPE } from 'const';
import { useDidUpdateEffect } from 'utils/HookUtils';
import SingleChoice from './components/plugins/SingleChoice';
import SingleChoiceUnlock from './components/plugins/SingleChoiceUnlock';
import MultichoiceTextDrag from './components/plugins/MultichoiceTextDrag';
import Semaphore from './components/plugins/Semaphore';
import RoadSign from './components/plugins/RoadSign';
import {
  completeExamAttemptRequest,
  completeQuestionRequest,
} from 'features/examAttemptSlice';

const KEY = 'haui-pcmt';

interface ResultAttempt {
  _id: string;
  answer: string[];
  startTime: string;
  consumeTime: number; // seconds
  others?: ResultAttempt[];
  remainTime: number;
}

const Exam: React.FC = () => {
  const params: { id: string } = useParams();
  const location = useLocation();
  const query: { name?: string } = queryString.parse(location.search);

  const contestant = JSON.parse(localStorage.getItem('contestant') ?? '""');
  const history = useHistory();
  const dispatch = useDispatch();
  const { completingLtsExamAttempt } = useSelector(
    (state: RootState) => state.examAttemptSlice
  );

  const [creatingExamAttempt, setCreatingExamAttempt] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [stop, setStop] = useState(-1);
  const [isFailed, setIsFailed] = useState(false);
  const [createExamAttempt, setCreateExamAttempt] =
    useState<ExamAttempt | null>(null);
  const [resultAttempt, setResultAttempt] = useState<ResultAttempt[]>([]);
  const [value, setValue] = useState<string>('-1');
  const [data, setData] = useState<ExamAttempt[]>([]);
  const [indexAttempt, setIndexAttempt] = useState(0);
  const [isShowResult, setIsShowResult] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const isAnswerInOrder = createExamAttempt?.isAnswerInOrder ?? false;
  const failProcess: string = _.get(
    createExamAttempt,
    `attempt[${indexAttempt}].question.failProcess`,
    ''
  );
  const correctAnswer: string[] = _.get(
    createExamAttempt,
    `attempt[${indexAttempt}].question.correctAnswer`,
    []
  );

  const content: string = _.get(
    createExamAttempt,
    `attempt[${indexAttempt}].question.content`,
    ''
  );
  const answerLength: number = _.get(
    createExamAttempt,
    `attempt[${indexAttempt}].answerLength`,
    0
  );
  const options: OptionQuestion[] = _.get(
    createExamAttempt,
    `attempt[${indexAttempt}].question.options[0]`,
    []
  );

  const attempt: Attempt[] = _.get(createExamAttempt, 'attempt', []);

  // const timeDo = _.get(
  //   resultAttempt,
  //   `[${indexAttempt}].remainTime`,
  //   _.get(attempt, `[${indexAttempt}].timeLimit`, 0)
  // );

  const timeDo = useMemo(() => {
    const totalTimeUse = moment().diff(
      moment(
        _.get(
          resultAttempt,
          `[${indexAttempt}].startTime`,
          moment().toISOString()
        )
      ),
      'seconds'
    );
    return totalTimeUse >= _.get(attempt, `[${indexAttempt}].timeLimit`, 0)
      ? 0
      : _.get(attempt, `[${indexAttempt}].timeLimit`, 0) - totalTimeUse;
  }, [
    _.get(resultAttempt, `[${indexAttempt}].startTime`, moment().toISOString()),
    _.get(attempt, `[${indexAttempt}].timeLimit`, 0),
  ]);
  const sectionIndex = data.findIndex(
    (item) => item.examQuestion === createExamAttempt?.examQuestion
  );

  const handleExamAttempt = (): void => {
    if (createExamAttempt === null) return;
    const arrEmpty = CryptoJS.AES.encrypt(
      JSON.stringify({ id: '', userId: '', data: [] }),
      KEY
    ).toString();
    const localResultAttempt = localStorage.getItem(
      `result-attempt-${createExamAttempt?.id ?? createExamAttempt?._id}-${
        contestant?.id ?? crypto.randomUUID()
      }`
    );
    const localIndexAttempt = localStorage.getItem(
      `index-attempt-${createExamAttempt?.id ?? createExamAttempt?._id}-${
        contestant?.id ?? crypto.randomUUID()
      }`
    );

    let parseIndex = JSON.parse(localIndexAttempt ?? '"0"');
    const bytes = CryptoJS.AES.decrypt(localResultAttempt ?? arrEmpty, KEY);
    const parseBytes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const parseResult: ResultAttempt[] = parseBytes?.data ?? [];
    const attempt: Attempt[] = _.get(createExamAttempt, 'attempt', []);
    console.log(localIndexAttempt, attempt);
    try {
      if (
        !localResultAttempt ||
        !localIndexAttempt ||
        parseResult.length === 0 ||
        parseIndex > parseResult.length ||
        (createExamAttempt?.id ?? createExamAttempt?._id) !== parseBytes.id ||
        contestant?.id !== parseBytes.userId
      ) {
        if (attempt.length === 0) return;
        setResultAttempt([
          {
            _id: attempt[0]._id,
            startTime: moment().toISOString(),
            answer: [],
            consumeTime: 0,
            remainTime: attempt[0].timeLimit,
          },
        ]);
        localStorage.setItem(
          `index-attempt-${
            createExamAttempt?.id ??
            createExamAttempt?._id ??
            crypto.randomUUID()
          }-${contestant?.id ?? crypto.randomUUID()}`,
          JSON.stringify(0)
        );
      } else {
        // const timeLimit = _.get(attempt, `[${parseIndex}].timeLimit`, 0);
        // const remainTime = _.get(
        //   parseResult,
        //   `[${parseIndex}].remainTime`,
        //   timeLimit
        // );
        // const consumeTime = timeLimit - remainTime;
        let totalConsumeTime = moment().diff(
          moment(parseResult[parseIndex].startTime),
          'seconds'
        );
        let timeLimit = _.get(attempt, `[${parseIndex}].timeLimit`, 0);
        const rA: ResultAttempt[] = _.cloneDeep(parseResult);
        let iA = parseIndex;
        if (totalConsumeTime > timeLimit) {
          setValue('-1');
          handleDisabledSubmit();
        }
        let startTime = moment().toISOString();
        // let remainTime = 0;
        if (rA.length > 0) {
          startTime = _.last(rA)?.startTime || startTime;
          // remainTime = _.last(rA)?.remainTime || remainTime;
        }

        let isEnd = false;

        if (totalConsumeTime >= timeLimit) {
          while (totalConsumeTime > 0) {
            // if (remainTime < 1) {
            let consumeTime = timeLimit;
            if (totalConsumeTime >= timeLimit) {
              totalConsumeTime -= timeLimit;
              startTime = moment(startTime)
                .add('seconds', consumeTime)
                .toISOString();
              iA = parseIndex + 1;

              parseIndex++;
              timeLimit = _.get(attempt, `[${parseIndex}].timeLimit`, 0);
              rA.push({
                _id: attempt[parseIndex + 1]._id,
                startTime,
                answer: ['0:-1'],
                consumeTime: 0,
                remainTime: attempt[parseIndex + 1].timeLimit,
              });
            } else {
              consumeTime = totalConsumeTime;
              totalConsumeTime = 0;
              startTime = moment()
                .subtract('seconds', consumeTime)
                .toISOString();
            }
            if (attempt.length === 0) return;
            rA[parseIndex] = {
              ...rA[parseIndex],
              consumeTime,
            };
            if (parseIndex === attempt.length - 1) {
              setResultAttempt(rA);
              handleSubmitAnswers(rA);
              isEnd = true;
              return;
            }
            handleSubmitQuestion([rA[parseIndex]]);
          }
        }
        if (!isEnd) {
          setResultAttempt(rA);
          setIndexAttempt(iA);
          setTimeout(() => {
            handleAutoFillAnswers(rA, iA);
          }, 2000);
        }
      }
    } catch (__) {
      if (attempt.length === 0) return;
      setResultAttempt([
        {
          _id: attempt[0]._id,
          startTime: moment().toISOString(),
          answer: ['0:-1'],
          consumeTime: 0,
          remainTime: attempt[0].timeLimit,
        },
      ]);
      localStorage.setItem(
        `index-attempt-${
          createExamAttempt?.id ?? createExamAttempt?._id ?? crypto.randomUUID()
        }-${contestant?.id ?? crypto.randomUUID()}`,
        JSON.stringify(0)
      );
    }
  };

  function onChangeAttempt(remainTime: number): void {
    try {
      const attempt: Attempt[] = _.get(createExamAttempt, 'attempt', []);
      if (attempt.length === 0) return;
      setIsLoaded(false);
      const tmp = _.cloneDeep(resultAttempt);
      // const consumeTime = moment().diff(
      //   moment(tmp[indexAttempt].startTime),
      //   'seconds'
      // );

      const timeLimit = _.get(attempt, `[${indexAttempt}].timeLimit`, 0);
      // const remainTime = _.get(tmp, `[${indexAttempt}].remainTime`, 0);
      const consumeTime = timeLimit - remainTime;

      tmp[indexAttempt] = {
        ...tmp[indexAttempt],
        answer: handleGetValues(),
        consumeTime: consumeTime,
        remainTime: remainTime,
        others: handleGetOtherValues(
          consumeTime,
          tmp[indexAttempt].startTime,
          remainTime
        ),
      };

      if (failProcess === 'stop') {
        const correct = _.get(correctAnswer, '[0]', '0:-1');
        const code = correct.split(':')[1];
        const option = options.filter(
          (item) =>
            item.code.toString().trim() === code.toString().trim() &&
            item.content.toLowerCase().trim() === value.toLowerCase().trim()
        );
        if (option.length === 0) {
          if (timeLeft <= 1) {
            setIsShowResult(true);
            handleSubmitAnswers(tmp);
            setStop(0);
            setIsFailed(true);
            setTimeout(() => {
              setStop(-1);
            }, 3000);
          } else {
            notification.error({
              message: `Đáp án sai. Vui lòng nhập lại!`,
            });
          }
          return;
        } else {
          // setIsShowResult(true);
          // setStop(1);
          // setTimeout(() => {
          //   setIsShowResult(false);
          //   setStop(-1);
          // }, 3000);
        }
      }
      if (indexAttempt === attempt.length - 1) {
        setResultAttempt(tmp);
        handleSubmitAnswers(tmp);
        return;
      }
      handleSubmitQuestion([tmp[indexAttempt]]);
      setValue('-1');
      tmp.push({
        _id: attempt[indexAttempt + 1]._id,
        startTime: moment().toISOString(),
        answer: ['0:-1'],
        consumeTime: 0,
        remainTime: attempt[indexAttempt + 1].timeLimit,
      });
      setResultAttempt(tmp);

      setIndexAttempt(indexAttempt + 1);
      handleDisabledSubmit();
    } catch (error) {
      //
    }
  }

  function autoSave(): void {
    const tmp = _.cloneDeep(resultAttempt);

    if (tmp[indexAttempt]) {
      tmp[indexAttempt] = {
        ...tmp[indexAttempt],
        answer: handleGetValues(),
        others: handleGetOtherValues(
          tmp[indexAttempt].consumeTime,
          tmp[indexAttempt].startTime,
          tmp[indexAttempt].remainTime
        ),
      };
      handleSubmitQuestion([tmp[indexAttempt]]);
      setResultAttempt(tmp);
    }
  }

  function setRemainTime(remainTime: number): void {
    const tmp = _.cloneDeep(resultAttempt);
    if (tmp[indexAttempt]) {
      tmp[indexAttempt] = {
        ...tmp[indexAttempt],
        remainTime,
      };
      // localStorage.setItem('hadv', JSON.stringify(tmp));

      localStorage.setItem(
        `result-attempt-${
          createExamAttempt?.id ?? createExamAttempt?._id ?? crypto.randomUUID()
        }-${contestant?.id ?? crypto.randomUUID()}`,
        CryptoJS.AES.encrypt(
          JSON.stringify({
            id:
              createExamAttempt?.id ??
              createExamAttempt?._id ??
              crypto.randomUUID(),
            userId: contestant?.id ?? crypto.randomUUID(),
            data: tmp,
          }),
          KEY
        ).toString()
      );
    }
  }

  const handleDisabledSubmit = (): void => {
    setDisabled(true);
    // setTimeout(() => {
    //   setDisabled(false);
    // }, 500);
  };

  const handleChangeSection = (index: number): void => {
    setValue('-1');
    setResultAttempt([]);
    setIndexAttempt(0);
    setIsShowResult(false);
    setIsFailed(false);
    setDisabled(true);
    setStop(-1);
    setCreateExamAttempt(data[index]);
    localStorage.setItem(
      `${KEYS.section}-${params.id}-${contestant?.id ?? crypto.randomUUID()}`,
      JSON.stringify(btoa(data[index].id ?? data[index]._id))
    );
    // setSection
  };

  const handleSubmitAnswers = (rA: ResultAttempt[]): void => {
    setIsLoading(true);
    const others: ResultAttempt[][] = [];
    const tmp = rA.map((item) => {
      if (item.others !== undefined) {
        others.push(
          item.others.map((o) => ({
            ...o,
            consumeTime: Math.floor(
              item.consumeTime / ((item.others ?? []).length + 1)
            ),
          }))
        );
        return {
          ...item,
          consumeTime: Math.floor(
            item.consumeTime / ((item.others ?? []).length + 1)
          ),
        };
      }
      return item;
    });

    const payload: PayloadType = {
      param: createExamAttempt?.id ?? createExamAttempt?._id,
      body: {
        attempt: _.reverse(
          _.uniqBy(
            _.reverse(
              tmp
                .concat(others.reduce((prev, curr) => [...prev, ...curr], []))
                .map((item) => ({
                  _id: item._id,
                  answer: item.answer,
                  startTime: item.startTime,
                  consumeTime: item.consumeTime,
                }))
            ),
            '_id'
          )
        ),
      },
      callback: {
        success() {
          setResultAttempt([]);
          setIsShowResult(true);
          setDisabled(true);
          setValue('-1');
          setIndexAttempt(0);
          setIsLoading(false);
          localStorage.removeItem(
            `result-attempt-${
              createExamAttempt?.id ?? createExamAttempt?._id
            }-${contestant?.id ?? crypto.randomUUID()}`
          );
          localStorage.removeItem(
            `index-attempt-${createExamAttempt?.id ?? createExamAttempt?._id}-${
              contestant?.id ?? crypto.randomUUID()
            }`
          );
          localStorage.setItem(
            `is-have-result-${
              createExamAttempt?.id ?? createExamAttempt?._id
            }-${contestant?.id ?? crypto.randomUUID()}`,
            JSON.stringify(true)
          );
        },
        failed(mess) {
          if (mess === 'ExamAttempt is not in correct status') {
            setResultAttempt([]);
            setIsShowResult(true);
            setDisabled(true);
            setValue('-1');
            setIndexAttempt(0);
          }
        },
      },
    };
    // console.log(payload.body);
    dispatch(completeExamAttemptRequest(payload));
  };

  const handleSubmitQuestion = (rA: ResultAttempt[]): void => {
    const others: ResultAttempt[][] = [];
    const tmp = rA.map((item) => {
      if (item.others !== undefined) {
        others.push(
          item.others.map((o) => ({
            ...o,
            consumeTime: Math.floor(
              item.consumeTime / ((item.others ?? []).length + 1)
            ),
          }))
        );
        return {
          ...item,
          consumeTime: Math.floor(
            item.consumeTime / ((item.others ?? []).length + 1)
          ),
        };
      }
      return item;
    });

    const payload: PayloadType = {
      param: createExamAttempt?.id ?? createExamAttempt?._id,
      body: {
        attempt: _.reverse(
          _.uniqBy(
            _.reverse(
              tmp
                .concat(others.reduce((prev, curr) => [...prev, ...curr], []))
                .map((item) => ({
                  _id: item._id,
                  answer: item.answer,
                  startTime: item.startTime,
                  consumeTime: item.consumeTime,
                }))
            ),
            '_id'
          )
        ),
      },
      callback: {
        success() {
          //
        },
        failed() {
          //
        },
      },
    };
    // console.log(payload.body);
    dispatch(completeQuestionRequest(payload));
  };

  const handleTimeUp = (): void => {
    if (isShowResult || timeDo <= 0) return;
    onChangeAttempt(0);
  };

  const saveResultAttemptToLocal = (): void => {
    if (resultAttempt.length === 0 || creatingExamAttempt) return;
    localStorage.setItem(
      `result-attempt-${
        createExamAttempt?.id ?? createExamAttempt?._id ?? crypto.randomUUID()
      }-${contestant?.id ?? crypto.randomUUID()}`,
      CryptoJS.AES.encrypt(
        JSON.stringify({
          id:
            createExamAttempt?.id ??
            createExamAttempt?._id ??
            crypto.randomUUID(),
          userId: contestant?.id ?? crypto.randomUUID(),
          data: resultAttempt,
        }),
        KEY
      ).toString()
    );
  };

  const saveIndexAttemptToLocal = (): void => {
    if (indexAttempt === 0 || creatingExamAttempt) return;
    localStorage.setItem(
      `index-attempt-${createExamAttempt?.id ?? createExamAttempt?._id}-${
        contestant?.id ?? crypto.randomUUID()
      }`,
      JSON.stringify(indexAttempt)
    );
  };

  const handleBackAttempt = (): void => {
    if (sectionIndex === 0 && indexAttempt === 0) return; // câu đầu cuả section đầu thì không thể back
    if (indexAttempt === 0 && sectionIndex > 0) {
      // back section
    } else if (indexAttempt > 0) {
      // back attempt
    }
  };

  const handleAutoFillAnswers = (rA: ResultAttempt[], idx: number): any => {
    try {
      const answer = rA[idx].answer;
      const answerType = _.get(attempt, `[${idx}].question.answerType`, '');
      const answerLength: number = _.get(attempt, `[${idx}].answerLength`, 0);

      switch (answerType) {
        case ANSWER_TYPE.singleChoice:
          // giải mã

          setValue(_.get(answer[0].split(':'), '[1]', '-1'));
          if (_.get(answer[0].split(':'), '[1]', '-1') !== '-1') {
            setDisabled(false);
          }
          break;
        case ANSWER_TYPE.multiChoiceTextDrag:
          switch (_.get(attempt, `[${idx}].question.questionType`, '')) {
            case QUESTION_TYPE.video:
            case QUESTION_TYPE.text: // mật thư
              // ["1:THIẾU","2:NHI"]
              if (answer.length !== answerLength) return;
              // eslint-disable-next-line no-case-declarations

              new Array(answerLength).fill(null).forEach((__, index) => {
                const targetBox = document.getElementById(
                  `${idx}-national-language/white-paper-${index}`
                ) as HTMLInputElement | null;
                if (targetBox) {
                  const v = _.get(answer[index].split(':'), '[1]', '-1');
                  targetBox.value = v !== '-1' ? v : '';
                  if (v !== '-1' && disabled) {
                    setDisabled(false);
                  }
                }
              });

              _.get(rA, `[${idx}].others`, []).map(
                (item: ResultAttempt, index) => {
                  // Bạch Văn
                  const a = item.answer;

                  new Array(a.length).fill(null).forEach((__, i) => {
                    const targetBox = document.getElementById(
                      `${idx}-national-language/white-paper-${index}-${i}`
                    ) as HTMLInputElement | null;
                    if (targetBox) {
                      const v = _.get(a[i].split(':'), '[1]', '-1');
                      targetBox.value = v !== '-1' ? v : '';
                      if (v !== '-1' && disabled) {
                        setDisabled(false);
                      }
                    }
                  });
                }
              );

              break;

            default:
              break;
          }
          break;
        case ANSWER_TYPE.multiChoiceImgDrag:
          switch (_.get(attempt, `[${idx}].question.questionType`, '')) {
            case QUESTION_TYPE.text: // mật thư
              break;

            default:
              break;
          }
          break;
        default:
          break;
      }
    } catch (error) {
      //
    }
  };

  const handleGetValues = (): string[] => {
    const values: string[] = [];
    const answerType = _.get(
      attempt,
      `[${indexAttempt}].question.answerType`,
      ''
    );
    const answerLength: number = _.get(
      attempt,
      `[${indexAttempt}].answerLength`,
      0
    );
    let tmp = true;

    switch (answerType) {
      case ANSWER_TYPE.singleChoice:
        // giải mã
        values.push(`0:${value}`);
        if (value !== '-1') {
          tmp = false;
        }
        break;
      case ANSWER_TYPE.multiChoiceTextDrag:
        switch (_.get(attempt, `[${indexAttempt}].question.questionType`, '')) {
          case QUESTION_TYPE.video:
          case QUESTION_TYPE.text: // mật thư
            // ["1:THIẾU","2:NHI"]
            new Array(answerLength).fill(null).forEach((__, index) => {
              const targetBox = document.getElementById(
                `${indexAttempt}-national-language/white-paper-${index}`
              ) as HTMLInputElement | null;
              const boxValue =
                (targetBox?.value ?? '') === '' ? '-1' : targetBox?.value;
              if (boxValue !== '-1') {
                tmp = false;
              }
              values.push(`${index + 1}:${boxValue}`);
            });

            break;

          default:
            break;
        }
        break;
      case ANSWER_TYPE.multiChoiceImgDrag:
        switch (_.get(attempt, `[${indexAttempt}].question.questionType`, '')) {
          case QUESTION_TYPE.text: // mật thư
            // ["1:1","2:2"]
            new Array(answerLength).fill(null).forEach((__, index) => {
              const targetBox = document.getElementById(
                `${indexAttempt}-targetbox-${index}`
              ) as HTMLSpanElement | null;
              const boxValue =
                (targetBox?.innerText ?? '') === ''
                  ? '-1'
                  : targetBox?.innerText;
              if (boxValue !== '-1') {
                tmp = false;
              }
              values.push(`${index + 1}:${boxValue}`);
            });
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
    setDisabled(tmp);
    return values;
  };

  const handleGetOtherValues = (
    consumeTime: number,
    startTime: string,
    remainTime: number
  ): undefined | ResultAttempt[] => {
    const answerType = _.get(
      attempt,
      `[${indexAttempt}].question.answerType`,
      ''
    );
    const questionType = _.get(
      attempt,
      `[${indexAttempt}].question.questionType`,
      ''
    );
    if (
      answerType === ANSWER_TYPE.multiChoiceTextDrag &&
      (questionType === QUESTION_TYPE.text ||
        questionType === QUESTION_TYPE.video)
    ) {
      const others = _.get(attempt, `[${indexAttempt}].others`, []);
      if (others.length === 0) return undefined;
      return others.map((item: Attempt, index) => {
        // Bạch Văn
        const values: any = [];
        const length = _.get(item, `answerLength`, 0);
        new Array(length).fill(null).forEach((__, idx) => {
          const targetBox = document.getElementById(
            `${indexAttempt}-national-language/white-paper-${index}-${idx}`
          ) as HTMLInputElement | null;
          const value =
            (targetBox?.value ?? '') === '' ? '-1' : targetBox?.value;

          values.push(`${idx + 1}:${value}`);
        });
        return {
          answer: values,
          startTime,
          consumeTime,
          _id: item._id,
          remainTime,
        };
      });
    }
    return undefined;
  };

  const renderAnswerType = (): React.ReactNode => {
    const answerType: string = _.get(
      attempt,
      `[${indexAttempt}].question.answerType`,
      ''
    );

    const isHaveOption: boolean = _.get(
      attempt,
      `[${indexAttempt}].question.isHaveOption`,
      false
    );
    const isKeepOption: boolean = _.get(
      attempt,
      `[${indexAttempt}].question.isKeepOption`,
      false
    );

    switch (answerType) {
      case ANSWER_TYPE.singleChoice:
        if (isHaveOption) {
          // chọn một
          return (
            <div style={{ padding: 16 }}>
              <SingleChoice
                attempt={attempt}
                content={content}
                indexAttempt={indexAttempt}
                options={options}
                value={value}
                setValue={setValue}
              />
            </div>
          );
        }
        // giải mã
        return (
          <div style={{ padding: 16 }}>
            <SingleChoiceUnlock
              value={value}
              setValue={setValue}
              indexAttempt={indexAttempt}
              content={content}
            />
          </div>
        );
      case ANSWER_TYPE.multiChoiceTextDrag:
        switch (_.get(attempt, `[${indexAttempt}].question.questionType`, '')) {
          case QUESTION_TYPE.text:
            // Phần thi thực hành của mật thư
            return (
              <>
                <div style={{ padding: 16 }}>
                  <MultichoiceTextDrag
                    indexAttempt={indexAttempt}
                    content={content}
                    answerLength={answerLength}
                    setValue={setValue}
                    others={_.get(attempt, `[${indexAttempt}].others`, [])}
                  />
                </div>
              </>
            );
          // semaphore
          case QUESTION_TYPE.video:
            return (
              <Semaphore
                isLoaded={isLoaded}
                setIsLoaded={setIsLoaded}
                content={content}
                videoUrl={_.get(
                  attempt,
                  `[${indexAttempt}].question.othersContent.video.url`,
                  ''
                )}
                keyStorage={`${
                  createExamAttempt?.id ?? createExamAttempt?._id
                }-${contestant?.id ?? crypto.randomUUID()}`}
                options={options}
                indexAttempt={indexAttempt}
                answerLength={answerLength}
                setValue={setValue}
                isKeepOption={isKeepOption}
                others={_.get(attempt, `[${indexAttempt}].others`, [])}
                fn={() => handleAutoFillAnswers(resultAttempt, indexAttempt)}
              />
            );
          default:
            return;
        }
      case ANSWER_TYPE.multiChoiceImgDrag:
        switch (_.get(attempt, `[${indexAttempt}].question.questionType`, '')) {
          case QUESTION_TYPE.text:
            return (
              <RoadSign
                content={content}
                options={options}
                indexAttempt={indexAttempt}
                answerLength={answerLength}
                setValue={setValue}
                isKeepOption={isKeepOption}
              />
            );

          default:
            return;
        }
      default:
        return null;
    }
  };

  const reExam = (): void => {
    setIsLoading(false);
    data.map((item) => {
      localStorage.removeItem(
        `result-attempt-${item?.id ?? item?._id}-${
          contestant?.id ?? crypto.randomUUID()
        }`
      );
      localStorage.removeItem(
        `index-attempt-${item?.id ?? item?._id}-${
          contestant?.id ?? crypto.randomUUID()
        }`
      );
      localStorage.removeItem(
        `${KEYS.section}-${params.id}-${contestant?.id ?? crypto.randomUUID()}`
      );
      localStorage.removeItem(
        `${KEYS.currentTime}-${item?.id ?? item?._id}-${
          contestant?.id ?? crypto.randomUUID()
        }`
      );
    });
    setResultAttempt([]);
    setIndexAttempt(0);
    setIsLoading(false);
    setIsLoaded(false);
    setStop(-1);
    setIsFailed(false);
    setValue('-1');
    setIsShowResult(false);
    setDisabled(true);
  };

  useEffect(() => {
    const key = 'hdd-2024';
    const ltsExamAttempts = localStorage.getItem(KEYS.ltsExamAttempts);
    const arrEmpty = CryptoJS.AES.encrypt(JSON.stringify({}), key).toString();
    const bytes = CryptoJS.AES.decrypt(ltsExamAttempts ?? arrEmpty, key);
    const parseBytes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (
      !(params.id in parseBytes) ||
      typeof parseBytes[`${params.id}`] !== 'object'
    ) {
      history.goBack();
    } else {
      setData(
        parseBytes[`${params.id}`].map((item: ExamAttempt) => {
          const attempMap = item.attempt.map((att: Attempt) => {
            if (Array.isArray(att)) {
              return {
                ...att[0],
                timeLimit:
                  att[0].timeLimit +
                  att.slice(1).reduce((prev, curr) => prev + curr.timeLimit, 0),
                others: att.slice(1),
              };
            }
            return att;
          });
          return { ...item, attempt: attempMap };
        })
      );
    }
  }, [params.id]);

  useDidUpdateEffect(() => {
    if (createExamAttempt === null) return;
    const isHaveResult =
      JSON.parse(
        localStorage.getItem(
          `is-have-result-${createExamAttempt?.id ?? createExamAttempt?._id}-${
            contestant?.id ?? crypto.randomUUID()
          }`
        ) ?? JSON.stringify(false)
      ) === true;
    if (isHaveResult) {
      setIsShowResult(true);
      setCreatingExamAttempt(false);
      return;
    }
    const loop = setTimeout(() => {
      handleExamAttempt();
      setCreatingExamAttempt(false);
    }, 1000);
    return () => {
      clearTimeout(loop);
    };
  }, [createExamAttempt]);

  useDidUpdateEffect(() => {
    if (data.length === 0) return;
    const section = localStorage.getItem(
      `${KEYS.section}-${params.id}-${contestant?.id ?? crypto.randomUUID()}`
    );

    if (section === null) {
      setCreateExamAttempt(data[0]);
      localStorage.setItem(
        `${KEYS.section}-${params.id}-${contestant?.id ?? crypto.randomUUID()}`,
        JSON.stringify(btoa(data[0].id ?? data[0]._id))
      );
    } else {
      const indexSection = data.findIndex(
        (item) => item?.id ?? item._id === atob(JSON.parse(section))
      );

      if (indexSection === -1) {
        setCreateExamAttempt(data[0]);
        localStorage.setItem(
          `${KEYS.section}-${params.id}-${
            contestant?.id ?? crypto.randomUUID()
          }`,
          JSON.stringify(btoa(data[0].id ?? data[0]._id))
        );
      } else {
        setCreateExamAttempt(data[indexSection]);
        localStorage.setItem(
          `${KEYS.section}-${params.id}-${
            contestant?.id ?? crypto.randomUUID()
          }`,
          JSON.stringify(btoa(data[indexSection].id ?? data[indexSection]._id))
        );
      }
    }
  }, [data]);

  useDidUpdateEffect(() => {
    saveResultAttemptToLocal();
  }, [resultAttempt]);

  useDidUpdateEffect(() => {
    saveIndexAttemptToLocal();
  }, [indexAttempt]);

  useDidUpdateEffect(() => {
    const isHaveOption: boolean = _.get(
      attempt,
      `[${indexAttempt}].question.isHaveOption`,
      false
    );
    if (
      _.get(attempt, `[${indexAttempt}].question.answerType`, '') ===
        ANSWER_TYPE.singleChoice &&
      isHaveOption
    ) {
      autoSave();
    } else {
      const delayDebounceFn = setTimeout(() => {
        autoSave();
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [value]);

  const isVideo =
    _.get(attempt, `[${indexAttempt}].question.questionType`, '') ===
    QUESTION_TYPE.video;

  const title =
    isShowResult || data.length === 1 || sectionIndex > 1
      ? query?.name ?? ''
      : `Phần ${sectionIndex + 1}: ${
          sectionIndex === 0 ? 'Thi Lý Thuyết' : 'Thi Thực Hành'
        }`;
  console.log(isShowResult, attempt.length === 0, creatingExamAttempt);
  return (
    <>
      {(isShowResult || attempt.length === 0) && !creatingExamAttempt ? (
        <ReceiveResult
          handleChangeSection={handleChangeSection}
          data={data}
          sectionIndex={sectionIndex}
          stop={stop}
          isFailed={isFailed}
        />
      ) : (
        <div>
          {creatingExamAttempt || completingLtsExamAttempt || isLoading ? (
            <div
              style={{
                top: 0,
                paddingTop: '35vh',
                width: '100%',
                maxWidth: 1024,
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                background: 'rgba(255,255,255,.3)',
                position: 'absolute',
                zIndex: 10,
              }}
            >
              <Loading
                tipSpin={
                  isLoading ? 'Đang gửi kết quả. Vui lòng đợi...' : undefined
                }
                isSubSpin={isLoading}
                fn={isLoading ? reExam : undefined}
              />
            </div>
          ) : (
            <>
              {!isVideo || (isLoaded && isVideo) ? (
                <CountDown
                  handleTimeUp={handleTimeUp}
                  title={title}
                  handleBackAttempt={handleBackAttempt}
                  timeDo={timeDo}
                  isShowBack={
                    indexAttempt !== 0 && sectionIndex !== 0 && !isAnswerInOrder
                  }
                  indexAttempt={indexAttempt}
                  setRemainTime={setRemainTime}
                  timeLeft={timeLeft}
                  setTimeLeft={setTimeLeft}
                />
              ) : (
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    fontSize: '1.2rem',
                    background: COLORS.HEADER.yellow,
                    color: COLORS.TEXT.yellowGrey,
                    padding: '0.8rem',
                    fontWeight: '600',
                    position: 'fixed',
                    width: '100%',
                    maxWidth: 1024,
                    zIndex: 1,
                  }}
                >
                  {title}
                </div>
              )}

              <div style={{ height: '4.2rem' }}></div>
              {renderAnswerType()}

              <ButtonPrimary
                onClick={() => onChangeAttempt(timeLeft)}
                disabled={
                  disabled
                  // ||
                  // (ANSWER_TYPE.singleChoice ===
                  //   _.get(
                  //     attempt,
                  //     `[${indexAttempt}].question.answerType`,
                  //     ''
                  //   ) &&
                  //   value === '-1')
                }
                name={
                  // indexAttempt === attempt.length - 1 &&
                  // sectionIndex === data.length - 1
                  //   ? ''
                  //   :
                  'Trả lời'
                }
                color={COLORS.TEXT.yellowGrey}
                bgs={['#FFE492', '#FAC300']}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default memo(Exam);
