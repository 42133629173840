import { Button } from 'antd';

type Props = {
  name: string;
  bgs: string[];
  color: string;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  disabled?: boolean | undefined;
};

const ButtonPrimary: React.FunctionComponent<Props> = ({
  name,
  bgs,
  color,
  onClick,
  disabled,
}: Props) => {
  return (
    <Button
      className="button-shadow"
      style={{
        background: `linear-gradient(180deg, ${bgs[0]} 0%, ${bgs[1]} 90%)`,
        height: 48,
        color: color,
        fontSize: '1rem',
        borderRadius: '0.5rem',
        width: '96%',
        maxWidth: 400,
        fontWeight: '700',
        position: 'fixed',
        zIndex: 2,
        transform: 'translateX(-50%)',
        left: '50%',
        bottom: 8,
      }}
      htmlType={'button'}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </Button>
  );
};

export default ButtonPrimary;
