import { Space } from 'antd';
import ButtonPrimary from 'pages/components/ButtonPrimary';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import { RootState } from 'store';
import { COLORS } from 'style';
import { useHistory } from 'react-router';
import { Attempt, ExamAttempt, PayloadType } from 'type';
import { useDispatch } from 'react-redux';
import { createExamAttemptRequest } from 'features/examAttemptSlice';
import Failed from './plugins/Failed';
import Success from './plugins/Success';
import Result from 'pages/Result';

type Props = {
  data: ExamAttempt[];
  handleChangeSection: (_: number) => void;
  sectionIndex: number;
  stop: number;
  isFailed: boolean;
};

const ReceiveResult: React.FC<Props> = ({
  data,
  handleChangeSection,
  sectionIndex,
  stop,
  isFailed,
}) => {
  const query: { isEnd?: string } = queryString.parse(location.search);
  const isEnd = query?.isEnd ?? 'false';
  const isTotalResult =
    (data.length > 1 && sectionIndex === data.length - 1) || isEnd === 'true';

  const dispatch = useDispatch();
  const history = useHistory();
  const { createExamAttempt } = useSelector(
    (state: RootState) => state.examAttemptSlice
  );
  const { creatingExamAttempt } = useSelector(
    (state: RootState) => state.examAttemptSlice
  );
  const attempt: Attempt[] = _.get(
    data,
    `[${sectionIndex}]attempt`,
    []
  ) as Attempt[];

  const totalScore = _.get(createExamAttempt, 'totalScore', 0);
  const time = _.get(createExamAttempt, 'totalTime', 0);
  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (time % 60).toString().padStart(2, '0');
  useEffect(() => {
    if (isTotalResult) {
      return;
    }
    const payload: PayloadType = {
      body: {
        examQuestion: data[sectionIndex]?.examQuestion,
      },
      callback: {
        success() {
          //
        },
        failed() {
          //
        },
      },
    };
    dispatch(createExamAttemptRequest(payload));
  }, []);

  useEffect(() => {
    window.addEventListener('unload', () => {
      if (sectionIndex < data.length - 1 && !isFailed && stop !== 0) {
        handleChangeSection(sectionIndex + 1);
      }
    });
    return () => {
      window.removeEventListener('unload', () => {
        if (sectionIndex < data.length - 1 && !isFailed && stop !== 0) {
          handleChangeSection(sectionIndex + 1);
        }
      });
    };
  }, [sectionIndex, data, isFailed, stop]);

  if (stop === 0) {
    localStorage.removeItem('ltsExamAttempts');
    return <Failed />;
  }

  if (stop === 1) {
    return <Success />;
  }

  return creatingExamAttempt ? (
    <p
      style={{
        marginTop: 32,
        color: COLORS.TEXT.yellowGrey,
        fontWeight: '600',
        textTransform: 'uppercase',
        fontSize: '1rem',
        marginBottom: 0,
        textAlign: 'center',
      }}
    >
      Kết quả đang được cập nhật...
    </p>
  ) : isTotalResult ? (
    <Result />
  ) : (
    <div style={{ padding: 8 }}>
      <Space
        direction="vertical"
        style={{ width: '100%', paddingTop: 12 }}
        align="center"
      >
        <p
          style={{
            color: COLORS.TEXT.yellowGrey,
            fontWeight: '600',
            textTransform: 'uppercase',
            fontSize: '1rem',
            marginBottom: 0,
            textAlign: 'center',
          }}
        >
          Chúc mừng bạn đã hoàn thành phần thi!
        </p>
        <table style={{ color: '#595959' }}>
          <tbody>
            <tr>
              <td style={{ minWidth: 180, padding: '0.5rem 0rem' }}>
                Tổng số câu hỏi:
              </td>
              <td style={{ fontWeight: '600' }}>{attempt.length}</td>
            </tr>
            <tr>
              <td style={{ minWidth: 180, padding: '0.5rem 0rem' }}>
                Thời gian thi:
              </td>
              <td style={{ fontWeight: '600' }}>
                {minutes} phút {seconds} giây
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0.5rem 0rem' }}>Điểm đạt được:</td>
              <td style={{ fontWeight: '600', color: '#2b68a9' }}>
                {Number(totalScore.toFixed(2))} điểm
              </td>
            </tr>
          </tbody>
        </table>
      </Space>
      <ButtonPrimary
        name={sectionIndex >= data.length - 1 ? 'Đóng' : 'Tiếp tục'}
        color={COLORS.TEXT.yellowGrey}
        bgs={['#FFE492', '#FAC300']}
        onClick={() => {
          if (sectionIndex >= data.length - 1 || isFailed) {
            history.push('/contestant/home');
          } else {
            handleChangeSection(sectionIndex + 1);
          }
        }}
      />
    </div>
  );
};

export default ReceiveResult;
