import React, { useState, ChangeEvent, useRef } from 'react';
import {
  Carousel,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  notification,
  DatePicker,
  DatePickerProps,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Icon, {
  UserOutlined,
  MailOutlined,
  KeyOutlined,
  SolutionOutlined,
  ApartmentOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import * as yup from 'yup';
import { CarouselRef } from 'antd/lib/carousel';
import { useHistory } from 'react-router';

import { RootState } from 'store';
import Loading from 'pages/components/Loading';
import ButtonSubmit from 'pages/components/ButtonSubmit';

import bgUrl from 'assets/img/login_bg.png';
import { ReactComponent as LogoSvg } from 'assets/svgs/logo.svg';
import { COLORS } from 'style';
import { PayloadType } from 'type';
import { registerRequest } from 'features/accountSlice';
import moment from 'moment';
import { PROVINCES } from 'const';
import { FormInstance } from 'antd/es/form/Form';

const { Text } = Typography;

interface Info {
  name: string;
  dateOfBirth: null | string;
  gender: null | string;
  province: null | string;
  organization: null | string;
  position: string;
}

interface Auth {
  email: string;
  password: string;
  rePassword: string;
}

const Register: React.FC = () => {
  const minWidth = 330;

  const history = useHistory();
  const dispatch = useDispatch();

  const { registering } = useSelector(
    (state: RootState) => state.accountReducer
  );

  const carouselRef = useRef<CarouselRef>(null);

  const [formAuth] = Form.useForm();
  const [formInfo] = Form.useForm();

  const [indexCarouse, setIndexCarouse] = useState(0);
  const [auth, setAuth] = useState<Auth>({
    email: 'hadv@bfd.vn',
    password: '123456',
    rePassword: '123456',
  });
  const [info, setInfo] = useState<Info>({
    name: '',
    dateOfBirth: null,
    gender: null,
    province: null,
    organization: null,
    position: '',
  });

  const authSchema = yup.object().shape({
    email: yup
      .string()
      .email('Email không đúng định dạng!')
      .required('Email không được để trống!'),
    password: yup.string().required('Mật khẩu không được để trống!'),
    // rePassword: yup.string().required('Vui lòng xác nhận mật khẩu!'),
  });

  const infoSchema = yup.object().shape({
    name: yup.string().required('Tên thí sinh không được để trống!'),
    dateOfBirth: yup
      .date()
      .required('Ngày sinh không được để trống!')
      .nullable(),
    gender: yup.string().required('Giới tính không được để trống!').nullable(),
    province: yup
      .string()
      .required('Tỉnh/Thành phố không được để trống!')
      .nullable(),
    organization: yup
      .string()
      .required('Đơn vị công tác không được để trống!')
      .nullable(),
    position: yup.string().required('Chức vụ không được để trống!'),
  });

  const yupSyncAuth = {
    async validator({ field }: any, value: any) {
      await authSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const yupSyncInfo = {
    async validator({ field }: any, value: any) {
      await infoSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const validateConfirmPassword = ({ getFieldValue }: any): any => ({
    validator(_: any, value: any) {
      if (value === '') {
        return Promise.reject(new Error('Vui lòng xác nhận mật khẩu!'));
      }
      if (getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Mật khẩu không trùng khớp!'));
    },
  });

  const onChangeDate: DatePickerProps['onChange'] = (date): void => {
    setInfo({
      ...info,
      dateOfBirth: date ? moment(date).unix().toString() : null,
    });
  };
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (indexCarouse === 0) {
      setAuth({
        ...auth,
        [name]: value,
      });
      return;
    }

    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleOnChangeSelectGender = (value: {
    label: string;
    value: number;
  }): void => {
    setInfo({
      ...info,
      gender: value.label,
    });
  };

  const handleOnChangeSelectProvince = (value: {
    label: string;
    value: number;
  }): void => {
    setInfo({
      ...info,
      province: value.label,
    });
  };

  const handleSubmit = async (values: any): Promise<void> => {
    if (indexCarouse === 0) {
      carouseGoTo(1);
      return;
    }

    const {
      email,
      password,
      name,
      dateOfBirth,
      gender,
      province,
      organization,
      position,
    } = values;

    const payload: PayloadType = {
      body: {
        email,
        password,
        name,
        dateOfBirth,
        gender,
        province,
        organization,
        position,
      },
      callback: {
        success() {
          notification.success({
            message: 'Đăng ký tài khoản thành công!',
          });
          setTimeout(() => {
            history.push('/auth/login');
          }, 1000);
        },
        failed(message) {
          notification.error({
            message,
          });
        },
      },
    };
    dispatch(registerRequest(payload));
  };

  const carouseGoTo = (indexSlider: number): void => {
    if (!carouselRef.current?.goTo) return;
    carouselRef.current?.goTo(indexSlider);
    setIndexCarouse(indexSlider);
  };

  const toLogin = (): void => {
    history.push('/auth/login');
  };

  const onChange = (currentSlide: number): void => {
    setIndexCarouse(currentSlide);
  };

  const initialValues = indexCarouse === 0 ? { ...auth } : { ...info };
  const form: FormInstance<Auth | Info> =
    indexCarouse === 0 ? formAuth : formInfo;

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${bgUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        paddingTop: '2vh',
      }}
    >
      <Form
        name={indexCarouse === 0 ? 'normal_auth' : 'normal_info'}
        initialValues={initialValues}
        size={'large'}
        form={form}
        onFinish={handleSubmit}
      >
        <div
          style={{ width: minWidth, margin: '0px auto 1rem' }}
          className="d-flex flex-row align-items-center justify-content-between"
        >
          <Icon style={{ fontSize: '4rem' }} component={LogoSvg} />
          <div style={{}}>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: '0.8rem',
                color: COLORS.TEXT.red,
                margin: 0,
                fontWeight: '700',
              }}
            >
              hội đồng huấn luyện trung ương
            </Text>
            <br></br>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: '0.8rem',
                color: COLORS.TEXT.primary,
                margin: 0,
                fontWeight: '700',
              }}
            >
              trại huấn luyện kim đồng cấp i<br></br>toàn quốc năm 2024
            </Text>
          </div>
        </div>
        <Text
          className="text-center"
          style={{
            color: COLORS.TEXT.primary,
            display: 'table',
            margin: '0 auto 1rem',
          }}
        >
          Đăng ký tài khoản mới
        </Text>
        <Carousel
          ref={carouselRef}
          className={`custom-carousel ${
            indexCarouse === 0 ? 'disable-dots' : ''
          }`}
          dots
          infinite={false}
          afterChange={onChange}
          draggable={indexCarouse == 0 ? false : true}
        >
          <div>
            {/* page 1 */}
            <Row style={{ margin: '0 auto 1rem' }}>
              <Col
                style={{
                  margin: '0 auto',
                }}
                xl={8}
                md={12}
                sm={18}
                xs={24}
              >
                <Space
                  size={'middle'}
                  direction="vertical"
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <Input.Group size="large">
                    <Space size={'small'} direction="vertical">
                      <Form.Item name="email" rules={[yupSyncAuth]}>
                        <Input
                          placeholder="Nhập tên tài khoản"
                          prefix={<MailOutlined style={{ fontSize: 18 }} />}
                          autoComplete="off"
                          style={{
                            borderRadius: '0.5rem',
                            width: minWidth,
                          }}
                          onChange={handleOnChange}
                        />
                      </Form.Item>
                      <Form.Item name="password" rules={[yupSyncAuth]}>
                        <Input.Password
                          placeholder="Nhập mật khẩu"
                          autoComplete="off"
                          style={{
                            borderRadius: '0.5rem',
                            width: minWidth,
                          }}
                          visibilityToggle={{
                            visible: false,
                          }}
                          prefix={<KeyOutlined style={{ fontSize: 18 }} />}
                        />
                      </Form.Item>
                      <Form.Item
                        name="rePassword"
                        rules={[validateConfirmPassword]}
                      >
                        <Input.Password
                          placeholder="Xác nhận mật khẩu"
                          autoComplete="off"
                          style={{
                            borderRadius: '0.5rem',
                            width: minWidth,
                          }}
                          visibilityToggle={{
                            visible: false,
                          }}
                          prefix={<KeyOutlined style={{ fontSize: 18 }} />}
                        />
                      </Form.Item>
                    </Space>
                  </Input.Group>
                </Space>
              </Col>
            </Row>
          </div>
          <div style={{}}>
            {/* page 2 */}
            {indexCarouse === 1 && (
              <Row style={{ margin: '0 auto 1rem' }}>
                <Col
                  style={{
                    margin: '0 auto',
                  }}
                  xl={8}
                  md={12}
                  sm={18}
                  xs={24}
                >
                  <Space
                    size={'middle'}
                    direction="vertical"
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    <Input.Group size="large">
                      <Space size={0} direction="vertical">
                        <Form.Item name="name" rules={[yupSyncInfo]}>
                          <Input
                            placeholder="Nhập tên tài khoản"
                            name="name"
                            prefix={<UserOutlined style={{ fontSize: 18 }} />}
                            autoComplete="off"
                            style={{
                              borderRadius: '0.5rem',
                              width: minWidth,
                            }}
                            onChange={handleOnChange}
                          />
                        </Form.Item>
                        <Form.Item name={'dateOfBirth'} rules={[yupSyncInfo]}>
                          <DatePicker
                            style={{ width: '100%', borderRadius: '0.5rem' }}
                            onChange={onChangeDate}
                            placeholder="Ngày sinh"
                            format={'DD/MM/YYYY'}
                          />
                        </Form.Item>
                        <Form.Item name="gender" rules={[yupSyncInfo]}>
                          <Select
                            placeholder={'Giới tính'}
                            defaultValue={null}
                            className="select-custom"
                            style={{}}
                            onChange={handleOnChangeSelectGender}
                            options={[
                              { value: 0, label: 'Nam' },
                              { value: 1, label: 'Nữ' },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item name="province" rules={[yupSyncInfo]}>
                          <Select
                            placeholder={'Chọn tỉnh/thành phố'}
                            defaultValue={null}
                            className="select-custom"
                            style={{}}
                            onChange={handleOnChangeSelectProvince}
                            options={PROVINCES}
                          />
                        </Form.Item>
                        <Form.Item name="organization" rules={[yupSyncInfo]}>
                          <Input
                            placeholder="Đơn vị công tác"
                            name="organization"
                            prefix={
                              <ApartmentOutlined style={{ fontSize: 18 }} />
                            }
                            autoComplete="off"
                            style={{
                              borderRadius: '0.5rem',
                              width: minWidth,
                            }}
                            onChange={handleOnChange}
                          />
                        </Form.Item>

                        <Form.Item name="position" rules={[yupSyncInfo]}>
                          <Input
                            placeholder="Chức vụ"
                            name="position"
                            prefix={
                              <SolutionOutlined style={{ fontSize: 18 }} />
                            }
                            autoComplete="off"
                            style={{
                              borderRadius: '0.5rem',
                              width: minWidth,
                            }}
                            onChange={handleOnChange}
                          />
                        </Form.Item>
                      </Space>
                    </Input.Group>
                  </Space>
                </Col>
              </Row>
            )}
          </div>
        </Carousel>
        <div
          style={{
            position: 'relative',
            width: minWidth,
            margin: '0px auto',
          }}
        >
          {indexCarouse === 1 && (
            <div
              onClick={() => carouseGoTo(0)}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                cursor: 'pointer',
              }}
            >
              <ArrowLeftOutlined style={{ fontSize: '18px' }} />
            </div>
          )}
          <Text
            className="text-center"
            style={{
              color: '#555',
              display: 'table',
              margin: '0 auto 1rem',
              fontSize: 12,
              fontWeight: '1rem',
            }}
          >
            {indexCarouse === 0
              ? '1. Thông tin tài khoản'
              : '2. Thông tin thí sinh'}
          </Text>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ width: '100%' }}
        >
          <ButtonSubmit
            style={{ width: minWidth, margin: '0px auto' }}
            name={indexCarouse === 0 ? 'Tiếp tục' : 'Đăng ký'}
            htmlType={'submit'}
          />
        </div>
      </Form>
      {registering && (
        <div
          style={{
            top: 0,
            paddingTop: '35vh',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgba(255,255,255,.3)',
            position: 'absolute',
          }}
        >
          <Loading />
        </div>
      )}
      {
        <div
          className="d-flex flex-row"
          style={{
            width: minWidth,
            color: COLORS.TEXT.yellowGrey,
            fontWeight: '500',
            fontSize: 14,
            margin: '0px auto',
            position: 'fixed',
            bottom: 8,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          Đã có tài khoản?&nbsp;
          <span
            onClick={toLogin}
            style={{ cursor: 'pointer', color: COLORS.TEXT.primary }}
          >
            Quay lại đăng nhập
          </span>
        </div>
      }
    </div>
  );
};

export default Register;
