import 'antd/dist/antd.min.css';
import ReactDOM from 'react-dom/client';
import Auth from 'layouts/Auth';
import { Provider } from 'react-redux';
import './index.css';
import store from 'store';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Client from 'layouts/Client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <Router forceRefresh={true}>
      <Switch>
        <Route path="/contestant" render={(props) => <Client {...props} />} />
        <Route path="/auth" render={(props) => <Auth {...props} />} />
        <Redirect from="*" to="/contestant/home" />
      </Switch>
    </Router>
  </Provider>
);
