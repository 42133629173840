import React, { useState, useEffect, memo } from 'react';

type Props = {
  miliseconds: number;
};

const Countdown: React.FC<Props> = ({ miliseconds }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(miliseconds);

  useEffect(() => {
    if (remainingSeconds === 0) return;

    const intervalId = setInterval(() => {
      setRemainingSeconds(remainingSeconds - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingSeconds]);

  const second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24;
  const days = Math.floor(remainingSeconds / day);
  const hours = Math.floor((remainingSeconds % day) / hour);
  const minutes = Math.floor((remainingSeconds % hour) / minute);
  const seconds = Math.floor((remainingSeconds % minute) / second);

  const formattedDays = days.toString().padStart(2, '0');
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return (
    <span>
      Mở sau: {formattedDays}d {formattedHours}h {formattedMinutes}m{' '}
      {formattedSeconds}s
    </span>
    // <span>
    //   {formattedDays} ngày {formattedHours} giờ {formattedMinutes} phút{' '}
    //   {formattedSeconds} giây
    // </span>
  );
};

export default memo(Countdown);
