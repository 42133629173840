import { Button, Tooltip } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { CloseOutlined } from '@ant-design/icons';
import { COLORS } from 'style';

import type { DragItem, OptionQuestion } from 'type';
import { isMobile } from 'utils';
import { useDidUpdateEffect } from 'utils/HookUtils';

export interface TargetBoxProps {
  lastDropped?: string;
  answer?: string;
  accept?: string[];
  indexAttempt: number;
  index: number;
  isRoadSign: boolean;
  code?: string;
  draged: { code: string; position: number }[];
  isKeepOption: boolean;
  onDrop: (_: string, __: any) => void;
  setDraged: (_: { code: string; position: number }[]) => void;
  setValue: (_: string) => void;
  setLastDropped: (_: string | null) => void;
  setCode: (_: string | null) => void;
  onClickOpenModalAnswers: (_: number) => void;
}

const TargetBox: FC<TargetBoxProps> = memo(function TargetBox({
  onDrop,
  lastDropped,
  answer,
  accept = [],
  indexAttempt,
  index,
  isRoadSign,
  code,
  draged,
  isKeepOption,
  setDraged,
  setValue,
  setLastDropped,
  setCode,
  onClickOpenModalAnswers,
}) {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: accept,
      drop(_item: DragItem, monitor) {
        if (!isKeepOption) {
          setDraged(
            _.unionBy(
              [
                ...draged,
                {
                  code: (_item?.id ?? '').toString(),
                  position: index,
                },
              ].filter((item) => item.code !== ''),
              'code'
            )
          );
        }
        setValue(moment().unix().toString());

        onDrop(_item?.id ?? '', monitor.getItemType());
        return undefined;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop, accept, draged]
  );

  const handleCancel = (): void => {
    setLastDropped(null);
    setCode(null);
    setValue(moment().unix().toString());
    if (!isKeepOption) {
      setDraged(
        _.unionBy(
          draged.filter(
            (item) => item.code.toString() !== (code ?? '').toString()
          ),
          'code'
        )
      );
    }
  };

  const style = {
    color: isOver || lastDropped || canDrop ? 'white' : 'black',
    background:
      isOver || lastDropped
        ? COLORS.HEADER.green
        : canDrop
        ? COLORS.HEADER.blue
        : 'rgba(128, 128, 128, 0.3)',
  };

  return (
    <div
      onClick={
        isOver || lastDropped
          ? handleCancel
          : () => onClickOpenModalAnswers(index)
      }
      style={{ position: 'relative' }}
    >
      {isRoadSign ? (
        <div
          ref={drop}
          role="TargetBox"
          className="d-flex justify-content-center align-items-center"
          style={{
            width: isMobile() ? '16.5vw' : 100,
            aspectRatio: 1 / 1,
            fontWeight: 800,
            borderRadius: '0.3rem',
            ...style,
          }}
        >
          <span
            id={`${indexAttempt}-targetbox-${index}`}
            style={{ fontSize: 0 }}
          >
            {code ?? ''}
          </span>
          {lastDropped ? (
            <img
              style={{
                width: isMobile() ? '16.5vw' : 100,
                aspectRatio: 1 / 1,
                borderRadius: '0.3rem',
              }}
              src={lastDropped}
            />
          ) : (
            answer
          )}
        </div>
      ) : (
        <Button
          id={`${indexAttempt}-targetbox-${index}`}
          ref={drop}
          value={code ?? ''}
          role="TargetBox"
          style={{
            borderRadius: '2rem',
            width: isMobile() ? '20vw' : 100,
            ...style,
          }}
        >
          {lastDropped ? lastDropped : answer} ??
        </Button>
      )}
      {lastDropped && (
        <Tooltip title="Huỷ đáp án">
          <Button
            style={{
              position: 'absolute',
              maxWidth: 16,
              maxHeight: 16,
              minWidth: 16,
              padding: 0,
              top: -5,
              right: -5,
            }}
            shape="circle"
            size="small"
            icon={<CloseOutlined style={{ fontSize: '0.5rem' }} />}
          />
        </Tooltip>
      )}
    </div>
  );
});

export interface StatefulTargetBoxState {
  lastDropped: string | null;
}

type Props = {
  answer: string;
  accept: string[];
  indexAttempt: number;
  index: number;
  isRoadSign: boolean;
  setValue: (_: string) => void;
  setDraged: (_: { code: string; position: number }[]) => void;
  draged: { code: string; position: number }[];
  isKeepOption: boolean;
  onClickOpenModalAnswers: (_: number) => void;
  itemSelected?: OptionQuestion | null;
  setItemSelected?: (_: null | number) => void;
};

export const StatefulTargetBox: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [lastDropped, setLastDropped] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const handleDrop = useCallback((id: string, value: string) => {
    setCode(id);
    setLastDropped(value);
  }, []);

  useDidUpdateEffect(() => {
    if (!props.itemSelected?.code || !props.itemSelected?.image) {
      return;
    }
    setCode(props.itemSelected.code.toString());
    setLastDropped(props.itemSelected.image);
    if (props.setItemSelected) {
      props.setItemSelected(null);
    }
  }, [props.itemSelected]);

  return (
    <TargetBox
      {...props}
      setLastDropped={setLastDropped}
      lastDropped={lastDropped as string}
      code={code as string}
      setCode={setCode}
      onDrop={handleDrop}
    />
  );
};
