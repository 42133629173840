import React, { memo } from 'react';
import { Input } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';

import { COLORS } from 'style';

type Props = {
  indexAttempt: number;
  content: string;
  value: string;
  setValue: (_: string) => void;
};

const SingleChoiceUnlock: React.FC<Props> = ({
  indexAttempt,
  content,
  value,
  setValue,
}) => {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={indexAttempt.toString()}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div>
          <div style={{ paddingTop: 12 }}>
            <span
              style={{
                color: COLORS.TEXT.yellowGrey,
                fontWeight: '600',
                fontSize: '0.9rem',
                marginBottom: 12,
              }}
              dangerouslySetInnerHTML={{
                __html: `Câu hỏi mật thư:&nbsp;<br/><span style="color: #595959;font-size: 1rem;font-weight: 400">${content}</span>`,
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            <p
              style={{
                color: COLORS.TEXT.yellowGrey,
                fontWeight: '600',
                fontSize: '0.9rem',
              }}
            >
              Chìa khóa giải mã mật thư:
            </p>
            <Input
              size="large"
              placeholder="Nhập đáp án"
              value={value === '-1' ? '' : value}
              style={{ borderRadius: '0.3rem' }}
              onChange={(event) => {
                setValue(event.target.value === '' ? '-1' : event.target.value);
              }}
            />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default memo(SingleChoiceUnlock);
