import { useEffect, useRef } from 'react';

export function useDidUpdateEffect(
  fn: React.EffectCallback,
  inputs?: React.DependencyList | undefined
): void {
  const fncRef: any = useRef();
  fncRef.current = fn;
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      return fncRef.current();
    }
  }, inputs);
}
