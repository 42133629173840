import React, { useState } from 'react';
import _ from 'lodash';
import Modal from 'antd/lib/modal/Modal';
import { Button, Space, Typography } from 'antd';
import { OptionQuestion } from 'type';
import { isMobile } from 'utils';
import { COLORS } from 'style';
const { Title, Text } = Typography;
type Props = {
  options: OptionQuestion[];
  isModalOpen: boolean;
  draged: { code: string; position: number }[];
  handleCancel: () => void;
  onConfirm: (_: number) => void;
};

const ModalRoadSign: React.FC<Props> = ({
  options,
  draged,
  isModalOpen,
  handleCancel,
  onConfirm,
}) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  function selectItemModal(item: OptionQuestion): void {
    setSelectedItem(item.code);
  }
  return (
    <Modal
      style={{ maxWidth: isMobile() ? '90vw' : '80vw' }}
      bodyStyle={{
        borderRadius: '0.3rem',
        padding: '1rem',
      }}
      maskStyle={{
        borderRadius: '0.3rem',
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      footer={null}
      open={isModalOpen}
      onCancel={() => {
        setSelectedItem(null);
        handleCancel();
      }}
    >
      <Title
        style={{
          textAlign: 'center',
          color: COLORS.TEXT.yellowGrey,
          marginBottom: 0,
        }}
        className="text-center"
        level={5}
      >
        Vui lòng chọn đáp án
      </Title>

      <Space direction="vertical" style={{ width: '100%', paddingTop: 12 }}>
        <p
          style={{
            color: COLORS.TEXT.yellowGrey,
            fontWeight: '600',
            fontSize: '0.9rem',
            marginBottom: 0,
          }}
        >
          Từ khoá gợi ý
        </p>

        <Space
          size={8}
          style={{
            justifyContent: 'center',
            overflowY: 'auto',
            maxHeight: '60vh',
          }}
          wrap
        >
          {options
            .filter(
              (item: OptionQuestion) =>
                !draged.map((d: any) => d.code).includes(item.code.toString())
            )
            .map((item: OptionQuestion) => (
              <div
                onClick={() => selectItemModal(item)}
                style={{
                  border:
                    selectedItem === item.code
                      ? `3px solid ${COLORS.HEADER.green}`
                      : '0.5px solid #eee',
                  borderRadius: '0.3rem',
                  transition: '250ms',
                }}
                className="button-shadow"
              >
                <img
                  style={{
                    width: isMobile() ? '16.5vw' : 100,
                    aspectRatio: 1 / 1,
                    borderRadius: '0.3rem',
                  }}
                  src={item.image}
                />
              </div>
            ))}
        </Space>

        <div className="d-flex justify-content-center">
          <Button
            onClick={() => {
              setSelectedItem(null);
              handleCancel();
            }}
            className="button-shadow"
            style={{
              background:
                'linear-gradient(rgb(221 217 203) 0%, rgb(193 193 193) 50%)',
              height: 26,
              color: '#2d2d2d',
              fontSize: '0.8rem',
              borderRadius: '0.5rem',
              margin: '0 10px',
              fontWeight: '700',
            }}
            type={'ghost'}
          >
            Huỷ
          </Button>
          <Button
            onClick={() => {
              if (!selectedItem) {
                handleCancel();
                return;
              }
              setSelectedItem(null);
              onConfirm(selectedItem);
            }}
            className="button-shadow"
            style={{
              background:
                'linear-gradient(180deg, rgba(255,228,146,1) 0%, rgba(250,195,0,1) 50%)',
              height: 26,
              color: '#6E6601',
              fontSize: '0.8rem',
              borderRadius: '0.5rem',
              margin: '0 10px',
              fontWeight: '700',
            }}
            type={'ghost'}
          >
            Xác nhận
          </Button>
        </div>
      </Space>
    </Modal>
  );
};

export default ModalRoadSign;
