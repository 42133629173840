import { Space } from 'antd';
import { getStatusExamQuestionRequest } from 'features/examAttemptSlice';
import _ from 'lodash';
import ButtonPrimary from 'pages/components/ButtonPrimary';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RootState } from 'store';
import { COLORS } from 'style';
import { ResultSubmit } from 'type';

const Result: React.FC = () => {
  const params: { id: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { gettingStatusExamQuestion } = useSelector(
    (state: RootState) => state.examAttemptSlice
  );

  const [results, setResults] = useState<ResultSubmit[]>([]);

  useEffect(() => {
    dispatch(
      getStatusExamQuestionRequest({
        other: (params?.id ?? '-').split('-'),
        callback: {
          success: (data: ResultSubmit[]) => {
            setResults(data);
          },
          failed: () => {
            //
          },
        },
      })
    );
  }, []);
  const renderTotalTime = (): React.ReactNode => {
    const totalTime = results.reduce((prev, curr) => {
      const time = _.get(curr, 'totalTime', 0);
      return prev + time;
    }, 0);
    const totalScore = results.reduce((prev, curr) => {
      const score = _.get(curr, 'totalScore', 0);
      return prev + score;
    }, 0);
    const minutes = Math.floor(totalTime / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (totalTime % 60).toString().padStart(2, '0');
    if (results.length === 1) return;
    return (
      <table style={{ color: '#595959' }}>
        <tbody>
          <tr>
            <td
              style={{
                padding: '0rem 0rem',
                fontWeight: 600,
                fontSize: '1rem',
                minWidth: 180,
              }}
            >
              Tổng thời gian thi
            </td>
            <td style={{ fontWeight: '600', color: '#2b68a9' }}>
              {minutes} phút {seconds} giây
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: '0rem 0rem',
                fontWeight: 600,
                fontSize: '1rem',
                minWidth: 180,
              }}
            >
              Tổng điểm đạt được
            </td>
            <td style={{ fontWeight: '600', color: '#2b68a9' }}>
              {Number(totalScore.toFixed(2))} điểm
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return gettingStatusExamQuestion ? (
    <p
      style={{
        marginTop: 32,
        color: COLORS.TEXT.yellowGrey,
        fontWeight: '600',
        textTransform: 'uppercase',
        fontSize: '1rem',
        marginBottom: 0,
        textAlign: 'center',
      }}
    >
      Kết quả đang được cập nhật...
    </p>
  ) : (
    <div style={{ padding: 8 }}>
      <Space
        direction="vertical"
        style={{ width: '100%', paddingTop: 12 }}
        align="center"
      >
        <p
          style={{
            color: COLORS.TEXT.yellowGrey,
            fontWeight: '600',
            textTransform: 'uppercase',
            fontSize: '1rem',
            marginBottom: 0,
            textAlign: 'center',
          }}
        >
          Chúc mừng bạn đã hoàn thành phần thi!
        </p>
        {results.map((item, index, self) => {
          const totalScore = _.get(item, 'totalScore', 0);
          const time = _.get(item, 'totalTime', 0);
          const minutes = Math.floor(time / 60)
            .toString()
            .padStart(2, '0');
          const seconds = (time % 60).toString().padStart(2, '0');
          return (
            <table style={{ color: '#595959' }} key={index}>
              <tbody>
                {self.length > 1 && (
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        padding: '0rem 0rem',
                        fontWeight: 600,
                        fontSize: '1rem',
                      }}
                    >
                      {index === 0
                        ? 'Phần 1: Thi lý thuyết'
                        : 'Phần 2: Phần thi thực hành'}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ minWidth: 180, padding: '0.5rem 0rem' }}>
                    Thời gian thi:
                  </td>
                  <td style={{ fontWeight: '600', color: '#2b68a9' }}>
                    {minutes} phút {seconds} giây
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0.5rem 0rem' }}>Điểm đạt được:</td>
                  <td style={{ fontWeight: '600', color: '#2b68a9' }}>
                    {Number(totalScore.toFixed(2))} điểm
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
        {renderTotalTime()}
      </Space>
      <ButtonPrimary
        name={'Đóng'}
        color={COLORS.TEXT.yellowGrey}
        bgs={['#FFE492', '#FAC300']}
        onClick={() => history.push('/contestant/home')}
      />
    </div>
  );
};

export default Result;
