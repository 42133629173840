import { createSlice } from '@reduxjs/toolkit';
import { ExamAttempt, ResultSubmit } from 'type';

interface InitState {
  // loading
  creatingExamAttempt: boolean;
  completingExamAttempt: boolean;
  creatingLtsExamAttempt: boolean;
  completingLtsExamAttempt: boolean;
  gettingStatusExamQuestion: boolean;
  completingQuestion: boolean;
  // data
  createExamAttempt: null | ExamAttempt;
  createLtsExamAttempt: null | ExamAttempt[];
  completeExamAttempt: null | ResultSubmit;
  completeLtsExamAttempt: null | ResultSubmit[];
  isTookTheTest: boolean; // đã thi hay chưa
}

const initialState = {
  // loading
  creatingExamAttempt: false,
  creatingLtsExamAttempt: false,
  completingExamAttempt: false,
  completingLtsExamAttempt: false,
  gettingStatusExamQuestion: false,
  completingQuestion: false,
  // data
  createExamAttempt: null,
  createLtsExamAttempt: null,
  ltsCreateExamAttempt: null,
  completeExamAttempt: null,
  completeLtsExamAttempt: null,
  isTookTheTest: false,
} as InitState;

export const examAttempt = createSlice({
  name: 'examAttempt',
  initialState,
  reducers: {
    // eslint-disable-next-line
    createExamAttemptRequest: (state, action) => {
      state.creatingExamAttempt = true;
    },
    createExamAttemptSuccess: (state, action) => {
      state.creatingExamAttempt = false;
      state.createExamAttempt = action.payload;
    },
    createExamAttemptFail: (state) => {
      state.creatingExamAttempt = false;
    },
    // eslint-disable-next-line
    createLtsExamAttemptRequest: (state, action) => {
      state.creatingLtsExamAttempt = true;
    },
    createLtsExamAttemptSuccess: (state, action) => {
      state.creatingLtsExamAttempt = false;
      state.createLtsExamAttempt = action.payload;
    },
    createLtsExamAttemptFail: (state) => {
      state.creatingLtsExamAttempt = false;
    },
    // eslint-disable-next-line
    completeExamAttemptRequest: (state, action) => {
      state.completingExamAttempt = true;
    },
    completeExamAttemptSuccess: (state, action) => {
      state.completingExamAttempt = false;
      state.completeExamAttempt = action.payload;
    },
    completeExamAttemptFail: (state) => {
      state.completingExamAttempt = false;
    },
    // eslint-disable-next-line
    completeLtsExamAttemptRequest: (state, action) => {
      state.completingLtsExamAttempt = true;
    },
    completeLtsExamAttemptSuccess: (state, action) => {
      state.completingLtsExamAttempt = false;
      state.completeLtsExamAttempt = action.payload;
    },
    completeLtsExamAttemptFail: (state) => {
      state.completingLtsExamAttempt = false;
    },
    // eslint-disable-next-line
    getStatusExamQuestionRequest: (state, action) => {
      state.gettingStatusExamQuestion = true;
      state.isTookTheTest = false;
    },
    // eslint-disable-next-line
    getStatusExamQuestionSuccess: (state, action) => {
      state.gettingStatusExamQuestion = false;
      state.isTookTheTest = true;
    },
    getStatusExamQuestionFail: (state) => {
      state.gettingStatusExamQuestion = false;
      state.isTookTheTest = false;
    },

    // eslint-disable-next-line
    completeQuestionRequest: (state, action) => {
      state.completingQuestion = true;
    },
    // eslint-disable-next-line
    completeQuestionSuccess: (state, action) => {
      state.completingQuestion = false;
    },
    completeQuestionFail: (state) => {
      state.completingQuestion = false;
    },
  },
});
export const {
  createExamAttemptRequest,
  createExamAttemptSuccess,
  createExamAttemptFail,
  //
  createLtsExamAttemptRequest,
  createLtsExamAttemptSuccess,
  createLtsExamAttemptFail,
  //
  completeExamAttemptRequest,
  completeExamAttemptSuccess,
  completeExamAttemptFail,
  //
  completeLtsExamAttemptRequest,
  completeLtsExamAttemptSuccess,
  completeLtsExamAttemptFail,
  //
  getStatusExamQuestionRequest,
  getStatusExamQuestionSuccess,
  getStatusExamQuestionFail,
  //
  completeQuestionRequest,
  completeQuestionFail,
  completeQuestionSuccess,
} = examAttempt.actions;
export default examAttempt.reducer;
