import { all, fork } from 'redux-saga/effects';
import { accountSaga } from 'sagas/accountSaga';
import { sectionGroupSaga } from 'sagas/sectionGroupSaga';
import { examQuestionSaga } from 'sagas/examQuestionSaga';
import { examAttemptSaga } from 'sagas/examAttemptSaga';

function* rootSaga(): any {
  yield all([fork(accountSaga)]);
  yield all([fork(sectionGroupSaga)]);
  yield all([fork(examQuestionSaga)]);
  yield all([fork(examAttemptSaga)]);
}
export default rootSaga;
