import { Button } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { CSSProperties } from 'react';

type Props = {
  name: string;
  onClick?: () => void;
  size?: SizeType;
  style?: CSSProperties;
  icon?: JSX.Element;
  shape?: 'round' | 'circle' | 'default' | undefined;
  type?:
    | 'default'
    | 'link'
    | 'text'
    | 'ghost'
    | 'primary'
    | 'dashed'
    | undefined;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
};

const ButtonSubmit: React.FunctionComponent<Props> = ({
  name,
  onClick,
  icon,
  type = 'default',
  htmlType = 'button',
  shape,
  size = 'middle',
  style = {},
}: Props) => {
  return (
    <Button
      onClick={onClick}
      className="button-shadow"
      style={{
        background:
          'linear-gradient(180deg, rgba(255,228,146,1) 0%, rgba(250,195,0,1) 90%)',
        height: 48,
        color: '#6E6601',
        fontSize: '1rem',
        borderRadius: '0.5rem',
        width: '100%',
        fontWeight: '700',
        ...style,
      }}
      htmlType={htmlType}
      type={type}
      shape={shape}
      icon={icon}
      size={size}
    >
      {name}
    </Button>
  );
};

export default ButtonSubmit;
