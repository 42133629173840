import {
  getSectionGroupsFail,
  getSectionGroupsSuccess,
  getSectionGroupsWithInfoFail,
  getSectionGroupsWithInfoSuccess,
} from 'features/sectionGroupSlice';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { GET } from 'services/ServiceBase';
import ServiceURL from 'services/ServiceURL';
import { ActionType, ExamQuestion, SectionGroup } from 'type';
import _ from 'lodash';

export function* getSectionGroups(data: ActionType): any {
  const { callback, query } = data.payload;
  const url = `${ServiceURL.sectionGroups}?${query ?? ''}`;
  try {
    const res = yield call(GET, url);

    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
      yield put(getSectionGroupsFail());
    } else {
      const data = _.get(res, 'data', {});
      const results = data?.results ?? [];
      const examQuestionsApi: any[] = results.map((item: SectionGroup) => {
        return item.sections.map((s) => {
          const urlEQ = `${ServiceURL.examQuestions}?section=${s}`;
          return call(GET, urlEQ);
        });
      });
      const arr = examQuestionsApi.reduce(
        (prev, curr) => [...prev, ...curr],
        []
      );
      const dataEQ = yield all(arr);
      const newResults = results.map((item: SectionGroup) => {
        const examQuestions = dataEQ.filter((d: { id: string }) =>
          item.sections.includes(_.get(d, 'data.results[0].id', ''))
        );
        return {
          ...item,
          examQuestions: examQuestions.map((s: ExamQuestion) =>
            _.get(s, 'data.results[0]', {})
          ),
        };
      });
      yield put(getSectionGroupsSuccess({ ...data, results: newResults }));
      callback.success({ ...data, results: newResults });
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? '');
    yield put(getSectionGroupsFail());
  }
}

export function* getSectionGroupsWithInfo(data: ActionType): any {
  const { callback, query } = data.payload;
  const url = `${ServiceURL.sectionGroups}/examInfo?${query}`;
  try {
    const res = yield call(GET, url);

    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
      yield put(getSectionGroupsWithInfoFail());
    } else {
      yield put(getSectionGroupsWithInfoSuccess(res.data));
      callback.success(res.data);
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? '');
    yield put(getSectionGroupsWithInfoFail());
  }
}

export function* sectionGroupSaga(): any {
  yield takeLatest('sectionGroup/getSectionGroupsRequest', getSectionGroups);
  yield takeLatest(
    'sectionGroup/getSectionGroupsWithInfoRequest',
    getSectionGroupsWithInfo
  );
}
