import React, { memo, useEffect, useRef, useState } from 'react';
import { Space } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { UpSquareFilled, DownSquareFilled } from '@ant-design/icons';

import { SourceBox } from './SourceBox';
import { StatefulTargetBox as TargetBox } from './TargetBox';
import { COLORS } from 'style';
import { OptionQuestion } from 'type';
import { isMobile } from 'utils';
import ModalRoadSign from './ModalRoadSign';

type Props = {
  content: string;
  options: OptionQuestion[];
  indexAttempt: number;
  answerLength: number;
  setValue: (_: string) => void;
  isKeepOption: boolean;
};

const RoadSign: React.FC<Props> = ({
  content,
  options,
  indexAttempt,
  answerLength,
  setValue,
  isKeepOption,
}) => {
  const [draged, setDraged] = useState<{ code: string; position: number }[]>(
    []
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [top, setTop] = useState(0);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [itemClicked, setItemClicked] = useState<number | null>(null);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }
    ref.current.scrollTo({ behavior: 'smooth', top: top });
  }, [ref?.current, top]);

  const scrollToTop = (): void => {
    if (top === 0) {
      return;
    }
    setTop(top - 50 < 0 ? 0 : top - 50);
  };

  const scrollToBottom = (): void => {
    if (!ref?.current) {
      return;
    }
    setTop(
      top > ref.current?.scrollHeight - ref.current.clientHeight
        ? ref.current?.scrollHeight - ref.current.clientHeight
        : top + 50
    );
  };

  function onClickOpenModalAnswers(index: number): void {
    setIsModalOpen(true);
    setItemClicked(index);
  }

  function handleCancel(): void {
    setSelectedItem(null);
    setIsModalOpen(false);
  }

  function onConfirm(code: number): void {
    setSelectedItem(code);
    setIsModalOpen(false);
  }

  return (
    <div style={{ padding: 8, paddingBottom: '20vh' }}>
      <DndProvider backend={isMobile() ? TouchBackend : HTML5Backend}>
        <div style={{ paddingTop: 12 }}>
          <span
            style={{
              color: COLORS.TEXT.yellowGrey,
              fontWeight: '600',
              fontSize: '0.9rem',
              marginBottom: 12,
            }}
            dangerouslySetInnerHTML={{
              __html: `Câu chuyện:&nbsp;<span style="color: #595959;font-size: 1rem;font-weight: 400">${content}</span>`,
            }}
          />
        </div>
        <div
          style={{
            paddingTop: 12,
            maxHeight: isMobile() ? '30vh' : '50vh',
            overflowY: 'auto',
          }}
        >
          <p
            style={{
              color: COLORS.TEXT.yellowGrey,
              fontWeight: '600',
              fontSize: '0.9rem',
            }}
          >
            Kéo đáp án vào ô thích hợp hoặc nhấn vào đáp án để chọn từ khóa
          </p>
          <Space
            style={{ justifyContent: 'center' }}
            align="center"
            size={8}
            wrap
          >
            {new Array(answerLength).fill(null).map((_, index) => (
              <TargetBox
                accept={
                  draged.map((d) => d.position).includes(index)
                    ? []
                    : options
                        .filter(
                          (item) =>
                            !draged
                              .map((d) => d.code)
                              .includes(item.code.toString())
                        )
                        .map((item) => item?.image.toString() ?? '')
                }
                answer={`${index + 1}`}
                key={index}
                isRoadSign={true}
                index={index}
                indexAttempt={indexAttempt}
                setValue={setValue}
                draged={draged}
                setDraged={setDraged}
                isKeepOption={isKeepOption}
                itemSelected={options.find(
                  (option) =>
                    option.code === selectedItem && itemClicked === index
                )}
                setItemSelected={setSelectedItem}
                onClickOpenModalAnswers={onClickOpenModalAnswers}
              />
            ))}
          </Space>
        </div>
        <div
          style={{ width: '100%', marginTop: '5vh', paddingBottom: '8px' }}
          className="d-flex justify-content-between align-items-center"
        >
          <p
            style={{
              color: COLORS.TEXT.yellowGrey,
              fontWeight: '600',
              fontSize: '0.9rem',
              marginBottom: 0,
            }}
          >
            Từ khoá gợi ý
          </p>
          {isMobile() ? (
            <div className="d-flex flex-column">
              <div style={{ cursor: 'pointer' }} onClick={scrollToTop}>
                <UpSquareFilled style={{ fontSize: '2rem' }} />
              </div>
              <div style={{ cursor: 'pointer' }} onClick={scrollToBottom}>
                <DownSquareFilled style={{ fontSize: '2rem' }} />
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
        <div
          ref={ref}
          style={{
            paddingTop: 12,
            maxHeight: isMobile() ? '25vh' : '50vh',
            overflowY: 'auto',
          }}
        >
          <Space size={8} style={{ justifyContent: 'center' }} wrap>
            {options
              .filter(
                (item) =>
                  !draged.map((d) => d.code).includes(item.code.toString())
              )
              .map((item, index) => (
                <SourceBox
                  key={index}
                  suggest={{ id: item.code, name: item.image }}
                  isRoadSign={true}
                />
              ))}
          </Space>
        </div>
      </DndProvider>
      <ModalRoadSign
        draged={draged}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        onConfirm={onConfirm}
        options={options}
      />
    </div>
  );
};

export default memo(RoadSign);
