import { Card, Col, Space, Typography } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { COLORS } from 'style';
import ModalSectionGroup from './ModalSectionGroup';
import { ExamQuestion, SectionGroup } from 'type';
import CountDown from './CountDown';

const { Text } = Typography;

type Props = {
  item: SectionGroup;
};

const CardTest: React.FunctionComponent<Props> = ({ item }: Props) => {
  const { name, icon, des, examInfo, sections } = item;
  const examQuestion = _.get(
    examInfo,
    '[0].examQuestions[0]',
    null
  ) as ExamQuestion | null;
  const examAttempt = (examInfo ?? [])
    .map((v) => _.get(v, 'attempts[0]', undefined))
    .filter(
      (v?: { status: string }) => v?.status === 'done' && v !== undefined
    );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isTookTheTest = sections.length === examAttempt.length;

  let timeDo = 0;
  let totalScore = 0;

  examAttempt.forEach((att) => {
    timeDo += _.get(att, 'totalTime', 0);
    totalScore += _.get(att, 'totalScore', 0);
  });

  const minutes = Math.floor(timeDo / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeDo % 60).toString().padStart(2, '0');

  const timeLeft = moment(examQuestion?.startTime ?? new Date()).diff(
    moment(),
    'seconds'
  );

  const textButton = checkStatusGroupSection();

  function checkStatusGroupSection(): string {
    if (
      moment().unix() < moment(examQuestion?.startTime ?? new Date()).unix()
    ) {
      return 'Mở sau';
    }

    if (moment().unix() < moment(examQuestion?.endTime ?? new Date()).unix()) {
      const attempting = (examInfo ?? [])
        .map((v) => _.get(v, 'attempts[0]', undefined))
        .filter(
          (v?: { status: string }) =>
            v?.status === 'attempting' && v !== undefined
        );
      if (attempting.length > 0) return 'Tiếp tục';
      return 'Vào thi';
    }
    return 'Đã đóng';
  }

  const circleRadius = 2;
  const borderColor = [
    'rgba(252, 200, 41, 1)',
    'rgba(201, 48, 141, 1)',
    'rgba(251, 149, 34, 1)',
    'rgba(55, 176, 244, 1)',
    'rgba(94, 205, 32, 1)',
    'rgba(184, 112, 76, 1)',
  ][Math.floor(Math.random() * 5)];

  const showModal = (): void => {
    setIsModalOpen(true);
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
  };

  return (
    <Col
      style={{ position: 'relative', marginTop: 16 }}
      xs={12}
      sm={12}
      md={8}
      lg={6}
    >
      {isModalOpen && (
        <ModalSectionGroup
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          item={item}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 1,
          width: `${circleRadius * 2}rem`,
          height: `${circleRadius * 2}rem`,
          borderRadius: `${circleRadius * 2}rem`,
          border: `3px solid ${borderColor}`,
          transform: 'translateX(-50%)',
          left: '50%',
          background: '#fff',
        }}
      >
        <img
          src={icon}
          width={'100%'}
          height={'100%'}
          style={{ borderRadius: 60 }}
        />
      </div>
      <div style={{ position: 'relative' }}>
        <Card
          className="button-shadow"
          style={{ borderRadius: '0.3rem', marginTop: `${circleRadius}rem` }}
          bodyStyle={{
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: `${circleRadius + 0.2}rem`,
            paddingBottom: `${circleRadius + 0.2}rem`,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            minHeight: 200,
          }}
        >
          <Space direction="vertical" size={1}>
            <div className="d-flex justify-content-center">
              <Text
                className="text-center"
                style={{ color: COLORS.TEXT.primary }}
                strong
              >
                {name}
              </Text>
            </div>
            <Text style={{ color: COLORS.TEXT.primary, fontSize: '0.75rem' }}>
              {des}
            </Text>

            <Text
              style={{
                color: COLORS.TEXT.primary,
                fontSize: '0.75rem',
                marginBottom: 12,
              }}
            >
              Ngày thi:{' '}
              <span style={{ color: COLORS.TEXT.primary, fontWeight: '600' }}>
                {`${
                  examQuestion?.startTime
                    ? moment(examQuestion.startTime).format('HH:mm DD/MM') +
                      ' - '
                    : ''
                }${
                  examQuestion?.endTime
                    ? moment(examQuestion.endTime).format('HH:mm DD/MM/YYYY')
                    : ''
                }`}
              </span>
              .
            </Text>

            {isTookTheTest && (
              <>
                <Text
                  style={{
                    color: COLORS.TEXT.primary,
                    fontSize: '0.75rem',
                    marginBottom: 12,
                  }}
                >
                  Thời gian:{' '}
                  <span
                    style={{ color: COLORS.TEXT.primary, fontWeight: '600' }}
                  >
                    {minutes} phút {seconds} giây
                  </span>
                  .
                </Text>
                <Text
                  style={{
                    color: COLORS.TEXT.primary,
                    fontSize: '0.75rem',
                    marginBottom: 12,
                  }}
                >
                  Điểm:{' '}
                  <span
                    style={{ color: COLORS.TEXT.primary, fontWeight: '800' }}
                  >
                    {Number(totalScore.toFixed(2))} điểm
                  </span>
                  .
                </Text>
              </>
            )}
          </Space>
        </Card>
        <div
          onClick={showModal}
          style={{
            bottom: 0,
            background: isTookTheTest
              ? 'linear-gradient(180deg, rgba(82, 196, 26, 1) 0%, rgba(82, 196, 26, 0.9) 90%)'
              : textButton === 'Mở sau'
              ? 'linear-gradient(180deg, rgba(89, 89, 89, 1) 0%, rgba(67, 67, 67, 1) 90%)'
              : 'linear-gradient(180deg, rgba(249, 197, 37, 1) 0%, rgba(226, 171, 6, 1) 90%)',
            width: '100%',
            position: 'absolute',
            borderBottomLeftRadius: '0.3rem',
            borderBottomRightRadius: '0.3rem',
            color:
              isTookTheTest || textButton === 'Mở sau'
                ? '#FFF'
                : COLORS.TEXT.yellowGrey,
            fontWeight: '700',
            padding: '2px',
            cursor: 'pointer',
          }}
          className="text-center"
        >
          {isTookTheTest ? (
            'Chi tiết kết quả'
          ) : textButton === 'Mở sau' ? (
            <CountDown miliseconds={timeLeft * 1000} />
          ) : (
            textButton
          )}
        </div>
      </div>
    </Col>
  );
};

export default CardTest;
