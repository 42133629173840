import React, { memo } from 'react';
import { Layout } from 'antd';
import ClientHeader from 'components/Headers/ClientHeader';
import {
  Redirect,
  Route,
  RouteComponentProps,
  StaticContext,
} from 'react-router';

import routes, { RouteItem } from 'routes';
import { Content } from 'antd/lib/layout/layout';
import { COLORS } from 'style';

import headerYellow from 'assets/img/header_yellow.png';
// import headerPink from 'assets/img/header_pink.png';
// import headerOrange from 'assets/img/header_orange.png';
// import headerGreen from 'assets/img/header_green.png';
// import headerBrown from 'assets/img/header_brown.png';
// import headerBlue from 'assets/img/header_blue.png';

const Client: React.FC<
  RouteComponentProps<any, StaticContext, unknown>
> = () => {
  const bg = COLORS.HEADER.yellow;
  const src = headerYellow;

  const getRoutes = (routes: RouteItem[]): React.ReactNode => {
    return routes.map((prop: RouteItem, key: number) => {
      if (prop.collapse) {
        return getRoutes(prop.views ?? []);
      }
      if (prop.layout === '/contestant') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };

  //kiểm tra đăng nhập
  if (
    !localStorage.getItem('contestant') ||
    !localStorage.getItem('access-token') ||
    !localStorage.getItem('refresh-token')
  ) {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('contestant');
    return <Redirect to="/auth/login" />;
  }
  return (
    <Layout>
      <ClientHeader bg={bg} src={src}>
        <Layout
          style={{
            minHeight: 'calc(100vh)',
          }}
        >
          <Content
            style={{
              backgroundColor: 'white',
            }}
          >
            {getRoutes(routes.routesCL)}
          </Content>
        </Layout>
      </ClientHeader>
    </Layout>
  );
};

export default memo(Client);
