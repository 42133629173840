import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Icon, { PhoneOutlined, KeyOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import * as yup from 'yup';
import _ from 'lodash';
import { useHistory } from 'react-router';
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

import { RootState } from 'store';
import Loading from 'pages/components/Loading';
import ButtonSubmit from 'pages/components/ButtonSubmit';

import bgUrl from 'assets/img/login_bg.png';
import { ReactComponent as LogoSvg } from 'assets/svgs/logo.svg';
import { COLORS } from 'style';
import { PayloadType } from 'type';
import { loginRequest } from 'features/accountSlice';

const { Title, Text } = Typography;

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const minWidth = 330;
  const { loginning } = useSelector((state: RootState) => state.accountReducer);
  const [visitorId, setVisitorId] = useState<string | null>(null);
  console.log('visitorId: ', visitorId);
  const [isRemember, setIsRemember] = useState<boolean>(
    JSON.parse(localStorage.getItem('isRemember') ?? 'true')
  );
  const auth = {
    phone: JSON.parse(localStorage.getItem('phone') ?? '""'),
    password: atob(JSON.parse(localStorage.getItem('password') ?? '""')),
  };

  const loginSchema = yup.object().shape({
    phone: yup.string().required('Tài khoản không được để trống!'),
    password: yup.string().required('Mật khẩu không được để trống!'),
  });

  const yupSync = {
    async validator({ field }: any, value: any) {
      await loginSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const handleChecked = (event: CheckboxChangeEvent): void => {
    const { checked } = event.target;
    setIsRemember(checked);
  };

  const handleSubmit = async (values: {
    phone: string;
    password: string;
  }): Promise<void> => {
    const { phone, password } = values;
    if (isRemember) {
      localStorage.setItem('phone', JSON.stringify(phone));
      localStorage.setItem('password', JSON.stringify(btoa(password)));
      localStorage.setItem('isRemember', JSON.stringify(isRemember));
    }

    const payload: PayloadType = {
      body: {
        phone: phone.toUpperCase(),
        password,
        deviceId: visitorId,
      },
      callback: {
        success(data) {
          const { tokens = null, user = null } = data;
          if (tokens === null || user === null) return;
          localStorage.setItem('contestant', JSON.stringify(user));
          localStorage.setItem(
            'access-token',
            JSON.stringify(_.get(tokens, 'access.token', ''))
          );
          localStorage.setItem(
            'refresh-token',
            JSON.stringify(_.get(tokens, 'refresh.token', ''))
          );
          // window.location.href = '/contestant/home';
          history.push('/contestant/home');
        },
        failed(error) {
          notification.error({
            message: error,
          });
        },
      },
    };
    dispatch(loginRequest(payload));
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     const a = document.getElementById('email') as HTMLInputElement | null;
  //     if (!a) return;
  //     a.value = 'hadfb';
  //   }, 2000);
  // }, []);

  // const toRegister = (): void => {
  //   history.push('/auth/register');
  // };
  useEffect(() => {
    const localVisitorId = localStorage.getItem('visitorId');
    if (!localVisitorId) {
      const loadFingerprint = async (): Promise<void> => {
        const fp = await FingerprintJS.load();
        const result: GetResult = await fp.get();
        setVisitorId(result.visitorId);
        localStorage.setItem('visitorId', result.visitorId);
      };

      loadFingerprint();
    } else {
      setVisitorId(localVisitorId);
    }
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${bgUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Form
        name="normal_login"
        initialValues={{ isRemember: isRemember, size: 'large', ...auth }}
        size={'large'}
        onFinish={handleSubmit}
      >
        <Row style={{ margin: '0 auto', paddingTop: '5vh' }}>
          <Col
            style={{
              margin: '0 auto',
            }}
            xl={8}
            md={12}
            sm={18}
            xs={24}
          >
            <Space
              size={'middle'}
              direction="vertical"
              className="d-flex justify-content-center align-items-center"
              style={{
                flexDirection: 'column',
              }}
            >
              <Icon
                style={{ fontSize: '8rem', margin: '0 auto' }}
                component={LogoSvg}
              />
              <div>
                <Title
                  style={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    color: COLORS.TEXT.primary,
                    marginBottom: 0,
                  }}
                  className="text-center"
                  level={5}
                >
                  TRẠI HUẤN LUYỆN KIM ĐỒNG CẤP I TOÀN QUỐC NĂM 2024
                </Title>
                <Text
                  className="text-center"
                  style={{
                    color: COLORS.TEXT.primary,
                    display: 'table',
                    margin: '0 auto',
                  }}
                  strong
                >
                  Vui lòng đăng nhập để sử dụng
                </Text>
              </div>
              <Input.Group size="large">
                <Space size={'small'} direction="vertical">
                  <Form.Item name="phone" rules={[yupSync]}>
                    <Input
                      id="email"
                      placeholder="Nhập tên tài khoản"
                      prefix={<PhoneOutlined style={{ fontSize: 18 }} />}
                      autoComplete="off"
                      style={{
                        borderRadius: '0.5rem',
                        width: minWidth,
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="password" rules={[yupSync]}>
                    <Input.Password
                      placeholder="Nhập mật khẩu"
                      autoComplete="off"
                      style={{
                        borderRadius: '0.5rem',
                        width: minWidth,
                      }}
                      visibilityToggle={{
                        visible: false,
                      }}
                      prefix={<KeyOutlined style={{ fontSize: 18 }} />}
                    />
                  </Form.Item>
                </Space>
              </Input.Group>
              <div style={{ minWidth: minWidth }}>
                <Checkbox
                  name="isRemember"
                  checked={isRemember}
                  onChange={handleChecked}
                >
                  Ghi nhớ đăng nhập
                </Checkbox>
              </div>
              <ButtonSubmit
                style={{ minWidth: minWidth }}
                name="Đăng nhập"
                htmlType="submit"
              />
              {/* <div
                className="d-flex flex-row justify-content-end"
                style={{
                  minWidth: minWidth,
                  color: COLORS.TEXT.yellowGrey,
                  fontWeight: '500',
                  fontSize: 14,
                }}
              >
                Chưa có tài khoản?&nbsp;
                <span
                  onClick={toRegister}
                  style={{ cursor: 'pointer', color: COLORS.TEXT.primary }}
                >
                  Đăng ký ngay
                </span>
              </div> */}
            </Space>
          </Col>
        </Row>
      </Form>
      {loginning && (
        <div
          style={{
            top: 0,
            paddingTop: '35vh',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgba(255,255,255,.3)',
            position: 'absolute',
          }}
        >
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Login;
